import { useTranslations } from "next-intl"

import { PdpContext } from "@/lib/storefront/product/pdp-service"
import { determineEffectiveProductFromPdpContext } from "@/lib/storefront/product/productHelper"

type ProductDetailBrandLinkProps = {
  pdpContext: PdpContext
}

export default function ProductDetailBrandLink(props: ProductDetailBrandLinkProps) {
  const t = useTranslations("storefront")
  const productOrArticle = determineEffectiveProductFromPdpContext(props.pdpContext)

  if (!productOrArticle?.brand || !productOrArticle?.brandLinkUrl) {
    return <></>
  }

  return (
    <>
      {productOrArticle && productOrArticle.brand && (
        <span className={""}>
          {t("product.go_to_brandshop") + " "}
          <a className={"font-bold text-primary"} href={productOrArticle.brandLinkUrl}>
            {productOrArticle.brand}
          </a>
        </span>
      )}
    </>
  )
}
