import { ResourceAttribute, VariationAttributeRO } from "@ub/product-client"

import { getBoolean } from "@/lib/helper"
import { CardPrices } from "@/lib/price-utils"
import { PdpContext } from "@/lib/storefront/product/pdp-service"
import { FullMasterProductData, ReducedProductData } from "@/lib/storefront/product/product"

export function determineEffectiveProductFromPdpContext(
  pdpContext: PdpContext
): ReducedProductData | FullMasterProductData {
  const article = pdpContext.productAndArticle.article
  const masterProduct = pdpContext.productAndArticle.masterProduct
  let result: ReducedProductData | FullMasterProductData | undefined
  if (article) {
    result = article
  } else if (masterProduct) {
    result = masterProduct
  }
  if (!result) {
    throw new Error("determineEffectiveProductFromPdpContext: No product found")
  }
  return result
}

export function determineEffectiveProductId(productId: string) {
  let effectiveProductId = productId.toUpperCase().trim() //"e"->"E" //"a"->"A"
  if (effectiveProductId.startsWith("A")) {
    effectiveProductId = productId.replace("A", "")
  }

  return effectiveProductId
}

export function extractEffectiveProductIdFromIntershopUri(uri: string | undefined) {
  // Attention: This is different to extractProductIdFromSlug() which extracts the id from an "outside URL"
  if (uri) {
    // console.log("Extracting product id from uri: "+uri)
    const uriSplit = uri.split("/")
    if (uriSplit.length > 1) {
      return determineEffectiveProductId(uriSplit[uriSplit.length - 1])
    }
  }
}

export function getCurrentFilterValue(article: ReducedProductData | undefined, variationAttributeId) {
  const variableVariationAttribute: VariationAttributeRO | undefined = article?.variableVariationAttributes?.find(
    (att) => {
      return att.variationAttributeId === variationAttributeId
    }
  )
  return variableVariationAttribute?.value as unknown as string
}

export function isVariantPossible(
  possibleVariants: Record<string, string>[],
  selectedFilterOptions: Record<string, string | undefined>,
  checkAttribute: string,
  checkValue: string
) {
  const newOptions = { ...selectedFilterOptions }
  newOptions[checkAttribute] = checkValue
  const possibleVariant = possibleVariants.find((pv) => {
    const testRecord = {}
    for (const key in newOptions) {
      testRecord[key] = pv[key]
    }
    return Object.entries(testRecord).toString() === Object.entries(newOptions).toString()
  })

  return !!possibleVariant
}

export function calculateVariantString(product: ReducedProductData) {
  let result = ""
  if (product && product.variableVariationAttributes) {
    for (let i = 0; i < product.variableVariationAttributes.length; i++) {
      const attributeValue = product.variableVariationAttributes[i]?.value
      if (attributeValue) {
        result += attributeValue + "/"
      }
    }
  }
  return result
}

export function calculateCategoryPathString(productOrArticle: ReducedProductData): string | undefined {
  return productOrArticle.defaultCategoryPath
    ?.filter((pathElem) => !!pathElem.name) // ...to be sure to have no empty elements
    .map((pathElem) => pathElem.name)
    .join("/")
}

export function getInvalidCombinationMasterLink(masterProductLink: string, attributeId: string, value: string) {
  let url = new URL(masterProductLink)
  url.searchParams.set("pdpsa", attributeId)
  url.searchParams.set("pdpsv", value)
  return url.toString()
}

export function findProductAttribute(
  productOrArticle: ReducedProductData | FullMasterProductData,
  attributeName: string
): ResourceAttribute | undefined {
  if (!productOrArticle.attributes || productOrArticle.attributes.length === 0) {
    return undefined
  }
  return productOrArticle.attributes.find((att: ResourceAttribute) => att.name === attributeName)
}

export function productNeedsQuotation(productOrArticle: ReducedProductData | FullMasterProductData): boolean {
  const quotationAttribute = findProductAttribute(productOrArticle, "NeedsQuotation")
  return getBoolean(quotationAttribute?.value)
}

export function showProductPrice(
  productOrArticle: ReducedProductData | FullMasterProductData | undefined,
  cardPrices: CardPrices
) {
  if (!productOrArticle) {
    return {
      showPrice: false,
      needsPriceQuotation: false,
    }
  }
  const isPriceEmpty = !cardPrices || isNaN(cardPrices.salePrice) || cardPrices.salePrice < 0.01
  const needsPriceQuotation = productNeedsQuotation(productOrArticle)

  const ret = {
    showPrice: !needsPriceQuotation && !isPriceEmpty,
    needsPriceQuotation: needsPriceQuotation,
  }

  // logger.debug(`showProductPrice(${productOrArticle.id}, ..) returns ${JSON.stringify(ret)}}`)

  return ret
}
