"use client"

import { FacetElementData } from "@/lib/factfinder/factfinder-interfaces"
import { formatAndReplacePrices } from "@/lib/helper"
import { getLinkWithEncryptedFfSearchParams } from "@/lib/link-utils/link-utils"
import { Category } from "@/lib/storefront/category/category"
import { cn } from "@/lib/utils"
import StoryblokAssetClient from "@/components/storyblok/common/StoryblokAssetClient"
import { AssetStoryblok } from "@/components/storyblok/component-types"

interface CustomBadgeFacetElementProps {
  facetElementData: FacetElementData
  query?: string
  baseCategory?: Category
  allFacetElements: FacetElementData[] | undefined
  isoLocale: string
  brandSlug?: string
  unit?: string
  icon?: AssetStoryblok
  handleSelect: (allElemData: FacetElementData[] | undefined, currentElemData: FacetElementData, link: string) => void
}

export default function CustomBadgeFacetElement(props: CustomBadgeFacetElementProps) {
  const elementname = props.facetElementData.elementname
  const selected = props.facetElementData.selected
  const hitcount = props.facetElementData.hitcount
  const active = hitcount > 0

  return (
    <div data-element-name={elementname}>
      <div
        className={"flex h-full cursor-pointer justify-between"}
        onClick={(event) => {
          if (active) {
            props.handleSelect(
              props.allFacetElements,
              props.facetElementData,
              getLinkWithEncryptedFfSearchParams(
                props.query,
                props.baseCategory,
                props.brandSlug,
                props.facetElementData.encryptedFfSearchParams,
                props.isoLocale
              )
            )
          } else {
            event.preventDefault()
          }
        }}
      >
        <div className="flex flex-row items-center gap-1 hover:text-primary">
          <div
            className={cn(
              "flex items-center rounded-sm border border-transparent bg-secondary p-2 text-sm hover:border-primary hover:bg-primary hover:text-primary-foreground",
              {
                "bg-primary text-primary-foreground border-primary": selected,
                "opacity-20 cursor-not-allowed hover:border-transparent hover:bg-secondary hover:text-secondary-foreground":
                  !active,
                "p-0": props.icon,
              }
            )}
          >
            {props?.icon && (
              <StoryblokAssetClient
                width={64}
                height={64}
                className={cn("size-10 rounded-sm", {
                  grayscale: !active,
                })}
                asset={props.icon}
              />
            )}
            <p
              className={cn("", {
                hidden: props.icon,
              })}
            >
              {formatAndReplacePrices(props.isoLocale, elementname) + " " + (props.unit ?? "")}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
