"use client"

import React from "react"

import { AccountContext } from "@/lib/storefront/account/account-service"
import AccountSignUpAddress from "@/components/auth/signup/AccountSignUpAddress"
import AccountSignUpB2BContact from "@/components/auth/signup/AccountSignUpB2BContact"
import AccountSignUpEmailAndPassword from "@/components/auth/signup/AccountSignUpEmailAndPassword"
import AccountSignUpLegal from "@/components/auth/signup/AccountSignUpLegal"

interface AccountSignUpB2BProps {
  accountContext: AccountContext
}

export default function AccountSignUpB2B(props: AccountSignUpB2BProps) {
  return (
    <section className={"relative block bg-cover "}>
      <div className={"mx-auto w-full max-w-7xl justify-center"}>
        <div className={"flex w-full flex-col items-start justify-start gap-2 pt-4"}>
          <div className={"w-full grow bg-ub-background"}>Geschäftskunde</div>
          <AccountSignUpEmailAndPassword accountContext={props.accountContext} />
          <AccountSignUpB2BContact accountContext={props.accountContext} />
          <AccountSignUpAddress accountContext={props.accountContext} />
          <AccountSignUpLegal accountContext={props.accountContext} />
        </div>
      </div>
    </section>
  )
}
