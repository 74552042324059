"use client"

import React, { useMemo } from "react"
import { useUbPdpState } from "@/store/UbpdpState"
import { useUbStorefrontState } from "@/store/ubStorefrontState"

import { PdpContext } from "@/lib/storefront/product/pdp-service"
import { getInvalidCombinationMasterLink, isVariantPossible } from "@/lib/storefront/product/productHelper"
import { cn } from "@/components/ui/helpers/utils"
import { UseFilteredVariantsHook } from "@/components/product/pdp/UseFilteredVariantsHook"

export interface FilterSelectorAttribute {
  id: string
  label: string
  currentValue?: string
}

export interface FilterSelectorValue {
  id: string
  label: string
  value: string
}

export interface BadgeFilterSelectorProps {
  attribute: FilterSelectorAttribute
  values: any
  pdpContext: PdpContext
}

function isActiveValue(value, currentActiveValue: string | undefined, currentValue: string | undefined) {
  if (currentActiveValue !== undefined) {
    return value === currentActiveValue
  } else {
    return value === currentValue
  }
}

export function BadgeFilterSelector(props: BadgeFilterSelectorProps) {
  const {
    selectedFilterOptions,
    selectOptionForAttribute,
    useClientSelectedFilterOptions,
    setFilterClicked,
    setSelectedFilterOptions,
  } = useUbPdpState()
  const { setIsLoading } = useUbStorefrontState()
  const masterProduct = props.pdpContext.productAndArticle.masterProduct
  const possibleVariants = UseFilteredVariantsHook(masterProduct).possibleVariants

  const currentActiveValue = useMemo(() => {
    return useClientSelectedFilterOptions && selectedFilterOptions[props.attribute.id]
      ? selectedFilterOptions[props.attribute.id]
      : props.attribute.label
  }, [props.attribute.id, props.attribute.label, selectedFilterOptions, useClientSelectedFilterOptions])

  function handleOnSelect(id: string, value: string) {
    if (isActiveValue(value, currentActiveValue, props.attribute.currentValue)) {
      return
    }

    setFilterClicked(true)

    if (isVariantPossible(possibleVariants, selectedFilterOptions, props.attribute.id, value)) {
      selectOptionForAttribute(id, value)
    } else {
      //Only when not redirecting away
      setSelectedFilterOptions({})
    }
  }

  const selectableValues = props.values.sort((a: any, b: any) =>
    a.value.value.localeCompare(b.value.value, props.pdpContext.isoLocale)
  )
  return (
    <div
      className={cn("flex w-full flex-col gap-4 opacity-100 transition-opacity duration-500 ease-in-out", {
        "opacity-0": !useClientSelectedFilterOptions,
      })}
    >
      <div className={"flex flex-col gap-2"}>
        <span className={"font-bold"}>{props.attribute.label}</span>
        <div className={"flex flex-row flex-wrap gap-2"}>
          {selectableValues.map((value, index) => (
            <div key={value.value.value} className={"grow sm:grow-0"}>
              {isVariantPossible(possibleVariants, selectedFilterOptions, props.attribute.id, value.value.value) ? (
                <div
                  onClick={() => handleOnSelect(props.attribute.id, value.value.value)}
                  className={cn("min-w-10 cursor-pointer rounded-lg border-2 border-gray-200 p-2 text-center", {
                    "border-primary cursor-default": isActiveValue(
                      value.value.value,
                      currentActiveValue,
                      props.attribute.currentValue
                    ),
                  })}
                >
                  {value.value.value}
                </div>
              ) : (
                <a
                  onClick={() => setIsLoading(true)}
                  href={getInvalidCombinationMasterLink(
                    props.pdpContext.masterProductLink ?? "",
                    props.attribute.id,
                    value.value.value
                  )}
                  className={
                    "block min-w-10 cursor-pointer rounded-lg border-2 border-dashed border-gray-200 p-2 text-center text-gray2"
                  }
                >
                  {value.value.value}
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
