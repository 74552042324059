"use client"

import React from "react"
import { useRouter, useSearchParams } from "next/navigation"
import { DEFAULT_SEARCH_RESULT_PAGE_SIZES, SEARCH_PARAMS_PAGE, SEARCH_PARAMS_PAGE_SIZE } from "@/constants/ui"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { useTranslations } from "next-intl"

import { Select, SelectContent, SelectItem, SelectTrigger } from "@/components/ui/components"
import { getPaginationSearchParams } from "@/components/search-and-category/pagination-info"

interface SearchResultPageSizeProps {
  hitcount: number
}

const SearchResultPageSize = (props: SearchResultPageSizeProps) => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const paginationSearchParams = getPaginationSearchParams(searchParams)
  const t = useTranslations("storefront")
  const { setIsLoading } = useUbStorefrontState()

  const handleChange = (value: string) => {
    setIsLoading(true)
    const newSearchParams = new URLSearchParams(searchParams)
    Number(value) === DEFAULT_SEARCH_RESULT_PAGE_SIZES[0]
      ? newSearchParams.delete(SEARCH_PARAMS_PAGE_SIZE)
      : newSearchParams.set(SEARCH_PARAMS_PAGE_SIZE, value)
    newSearchParams.set(SEARCH_PARAMS_PAGE, "1")
    router.push("?" + newSearchParams.toString())
  }

  return (
    <div>
      {props.hitcount > 0 && props.hitcount < 25 ? (
        <div className="flex h-10 w-full items-center justify-between border-none bg-transparent px-3 py-2 pl-0 pt-0 text-base">
          <span className="mr-2">{t("common.page_size", { size: "" })}</span>
          <span className="font-medium text-primary">{paginationSearchParams.pageSize}</span>
        </div>
      ) : (
        <Select
          aria-label={t("common.page_size", { size: "" })}
          value={paginationSearchParams.pageSize.toString()}
          onValueChange={(value: string) => handleChange(value)}
        >
          <SelectTrigger className="border-none bg-transparent pl-0 pt-0 text-base">
            <span className="mr-2">{t("common.page_size", { size: "" })}</span>
            <span className="font-medium text-primary">{paginationSearchParams.pageSize}</span>
          </SelectTrigger>
          <SelectContent className="bg-white">
            {DEFAULT_SEARCH_RESULT_PAGE_SIZES.map((pageSize) => (
              <SelectItem className="cursor-pointer" value={pageSize.toString()} key={pageSize}>
                {t("common.page_size", { size: pageSize })}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )}
    </div>
  )
}

export default SearchResultPageSize
