"use client"

import React, { useState } from "react"
import { useRouter } from "next/navigation"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { useTranslations } from "next-intl"

import { cn } from "@/lib/utils"
import { Label, Select, SelectContent, SelectItem, SelectTrigger, Switch } from "@/components/ui/components"
import StoryblokCustomerTypeDialog from "@/components/storyblok/header/StoryblokCustomerTypeDialog"

export default function StoryblokPrivateCustomerToggle({ isB2BCustomer, userData, isMobileVisible = false }) {
  const [dialogVisible, setDialogVisible] = useState<boolean>(false)
  const { setIsLoading } = useUbStorefrontState()
  const t = useTranslations("storefront")
  const router = useRouter()

  function handleOnClose() {
    setDialogVisible(false)
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 3000)
    router.refresh()
  }

  return (
    <>
      {dialogVisible && <StoryblokCustomerTypeDialog onClose={handleOnClose} />}

      {!userData &&
        (isMobileVisible ? (
          <div className="flex items-center space-x-2">
            <Label
              htmlFor="business-customer-mode"
              className={cn("tracking-tight xl:tracking-normal", {
                "font-bold": isB2BCustomer,
              })}
            >
              {t("customer.b2b")}
            </Label>
            <Switch
              aria-labelledby="business-customer-mode"
              id="business-customer-mode"
              checked={!isB2BCustomer}
              onCheckedChange={() => setDialogVisible(true)}
            />
            <Label
              htmlFor="business-customer-mode"
              className={cn("tracking-tight xl:tracking-normal", {
                "font-bold": !isB2BCustomer,
              })}
            >
              {t("customer.b2c")}
            </Label>
          </div>
        ) : (
          <div className="flex items-center space-x-2">
            <Select value={isB2BCustomer ? "b2b" : "b2c"} onValueChange={() => setDialogVisible(true)}>
              <SelectTrigger
                className="h-6 border-none p-0 text-xs font-bold text-black"
                aria-label={t("customer.b2b") + " | " + t("customer.b2c")}
              >
                {isB2BCustomer ? t("customer.b2b") : t("customer.b2c")}
              </SelectTrigger>
              <SelectContent className={"bg-white"}>
                {!isB2BCustomer && (
                  <SelectItem className="bg-white p-1 text-xs font-bold" value="b2b" aria-label={t("customer.b2b")}>
                    {t("customer.b2b")}
                  </SelectItem>
                )}
                {isB2BCustomer && (
                  <SelectItem className="bg-white p-1 text-xs font-bold" value="b2c" aria-label={t("customer.b2c")}>
                    {t("customer.b2c")}
                  </SelectItem>
                )}
              </SelectContent>
            </Select>
          </div>
        ))}
    </>
  )
}
