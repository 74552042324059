import { useMemo } from "react"
import { useRouter } from "next/navigation"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { SearchParams } from "@ub/fact-finder-client"
import { Info, XCircleIcon } from "lucide-react"

import { FacetData } from "@/lib/factfinder/factfinder-interfaces"
import { getResetSingleAttributeLinkToSearchResultOrCategory } from "@/lib/link-utils/link-utils"
import { Category } from "@/lib/storefront/category/category"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/components"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/components/tooltip"
import { hasFFSearchParam } from "@/components/search-and-category/customfilter/filterHelper"
import StoryblokAssetClient from "@/components/storyblok/common/StoryblokAssetClient"
import { CustomFilterStoryblok } from "@/components/storyblok/component-types"

import CustomBadgeFacet from "./CustomBadgeFacet"

export function CustomBadgeFilterWidget(props: {
  isoLocale: string
  customFilter?: CustomFilterStoryblok
  query: string | undefined
  baseCategory: Category | undefined
  facetData: FacetData | undefined
  ffSearchParams: SearchParams | undefined
}) {
  const { setIsLoading } = useUbStorefrontState()
  const router = useRouter()

  const isFilterSelected = useMemo(() => {
    return (
      props?.facetData?.selectedFacetElements &&
      props.facetData.selectedFacetElements.length > 0 &&
      hasFFSearchParam(props.facetData, props.ffSearchParams)
    )
  }, [props.facetData, props.ffSearchParams])

  function removeSelected() {
    setIsLoading(true)
    router.push(
      getResetSingleAttributeLinkToSearchResultOrCategory(
        props.isoLocale,
        props.facetData?.fieldName,
        props.ffSearchParams,
        props.query,
        props.baseCategory
      )
    )
  }

  /*  console.dir(props.ffSearchParams)
  if (isFilterSelected) {
    console.dir(props.facetData)
  }*/

  return (
    <div className={"flex flex-col gap-2"} data-attribute={props.facetData?.fieldName ?? ""}>
      <div className={"flex flex-row justify-between gap-2"}>
        <div className={"flex flex-row items-center gap-2"}>
          <p
            className={cn("font-bold", {
              "text-primary": isFilterSelected,
            })}
          >
            {props?.customFilter?.name && props.customFilter.name !== ""
              ? props?.customFilter?.name
              : props.facetData?.name}
          </p>
          {isFilterSelected && (
            <Button onClick={() => removeSelected()} variant={"ghost"} size={"tiny"}>
              <XCircleIcon className={"size-4 text-gray-400"} />
            </Button>
          )}
        </div>
        <div className={"flex flex-row items-center"}>
          {props?.customFilter?.hint && props.customFilter.hint !== "" && (
            <TooltipProvider delayDuration={50} skipDelayDuration={100}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Info className={"size-4 text-gray-400"} />
                </TooltipTrigger>
                <TooltipContent
                  side={"top"}
                  sideOffset={2}
                  alignOffset={2}
                  className={"rounded-sm border border-gray-200 bg-white max-w-96"}
                >
                  <p className={"text-sm text-gray-800"}>{props.customFilter.hint}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
      </div>
      <div>
        <div className={"flex flex-row items-center justify-start gap-4"}>
          {props?.customFilter?.icon && (
            <StoryblokAssetClient
              width={64}
              height={64}
              className={"ml-1 size-7 opacity-60"}
              asset={props.customFilter.icon}
            />
          )}

          <CustomBadgeFacet
            isoLocale={props.isoLocale}
            query={props.query}
            baseCategory={props.baseCategory}
            facetData={props.facetData}
            customFilter={props.customFilter}
          />
        </div>
      </div>
    </div>
  )
}
