import { formatDateLegacy } from "@/lib/helper"
import { getCountry } from "@/lib/locale/locale-common-utils"
import { ReducedProductData } from "@/lib/storefront/product/product"

interface AvailabilityData {
  availabilityText: string
  immediatelyAvailable: boolean
}

function availabilityData(availabilityText: string, immediatelyAvailable: boolean): AvailabilityData {
  return {
    availabilityText: availabilityText,
    immediatelyAvailable: immediatelyAvailable,
  }
}

export function calculateAvailabilityData(
  isoLocale: string,
  productData: ReducedProductData | undefined,
  t: any
): AvailabilityData {
  const availableFrom = availableFromDate(productData)
  if (availableFrom) {
    return availabilityData(t("product.available_from", { availableFrom: availableFrom }), false)
  }

  let min = productData?.readyForShipmentMin

  if (temporaryDeliveryTime(productData)) {
    min = productData?.temporaryDeliveryTime
  }

  if (productData?.availability === false || !min) {
    return availabilityData("", false)
  }

  if (getCountry(isoLocale) === "ch") {
    min = Math.max(min, 7)
  }

  if (min <= 2) {
    return availabilityData(t("product.shipment_within48"), true)
  } else if (min <= 3) {
    return availabilityData(t("product.shipment_days", { shipment: "2-3" }), false)
  } else if (min <= 5) {
    return availabilityData(t("product.shipment_days", { shipment: "3-5" }), false)
  } else if (min <= 7) {
    return availabilityData(t("product.shipment_days", { shipment: "5-7" }), false)
  } else if (min <= 10) {
    return availabilityData(t("product.shipment_days", { shipment: "7-10" }), false)
  } else if (min <= 15) {
    return availabilityData(t("product.shipment_days", { shipment: "10-15" }), false)
  } else if (min <= 20) {
    return availabilityData(t("product.shipment_days", { shipment: "15-20" }), false)
  } else if (min <= 25) {
    return availabilityData(t("product.shipment_weeks", { shipment: "4-5" }), false)
  } else if (min <= 30) {
    return availabilityData(t("product.shipment_weeks", { shipment: "5-6" }), false)
  } else if (min <= 35) {
    return availabilityData(t("product.shipment_weeks", { shipment: "6-7" }), false)
  } else if (min <= 40) {
    return availabilityData(t("product.shipment_weeks", { shipment: "7-8" }), false)
  } else if (min <= 45) {
    return availabilityData(t("product.shipment_weeks", { shipment: "8-9" }), false)
  } else if (min <= 50) {
    return availabilityData(t("product.shipment_weeks", { shipment: "9-10" }), false)
  } else {
    return availabilityData(t("product.shipment_later"), false)
  }
}

export function availableFromDate(productData: ReducedProductData | undefined): string | undefined {
  return productData?.availability === true &&
    productData.availableFrom !== undefined &&
    new Date() < productData.availableFrom
    ? formatDateLegacy(productData.availableFrom)
    : undefined
}

export function temporaryDeliveryTime(productData: ReducedProductData | undefined): boolean {
  return (
    productData?.availability === true &&
    productData?.temporaryDeliveryTime !== undefined &&
    productData.temporaryDeliveryTimeTill !== undefined &&
    new Date() < productData.temporaryDeliveryTimeTill
  )
}
