"use client"

import React, { useEffect, useMemo } from "react"
import Link from "next/link"
import { removePromoCodeFromCurrentBasketAction } from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { XCircleIcon } from "lucide-react"

import { formatPrice } from "@/lib/helper"
import { getCountry } from "@/lib/locale/locale-common-utils"
import { BasketContext, BasketTotalData } from "@/lib/storefront/basket/basket"
import { determineBasketTotalData } from "@/lib/storefront/basket/basketHelper"
import { Button, Skeleton } from "@/components/ui/components"
import BasicCard from "@/components/common/BasicCard"
import { UbBasketSummaryStoryblok } from "@/components/storyblok/component-types"

interface BasketSummaryProps {
  basketContext: BasketContext
  blok: UbBasketSummaryStoryblok
}

export default function BasketSummary(props: BasketSummaryProps) {
  const isoLocale = props.basketContext.isoLocale
  const isB2B = props.basketContext.isB2B

  const { basketData, basketLineItems, basketPromotionCodes, setBasketData } = useUbStorefrontState()

  const basketTotalData: BasketTotalData = useMemo(() => {
    //console.dir(basketData,{depth:5})
    return determineBasketTotalData(isB2B, basketData, basketLineItems, basketPromotionCodes)
  }, [basketData, basketLineItems, basketPromotionCodes, isB2B])

  useEffect(() => {
    console.dir(basketTotalData)
    console.dir(basketPromotionCodes)
  }, [basketPromotionCodes, basketTotalData])

  const showHandlingFeeHint = useMemo(() => {
    return getCountry(isoLocale) === "de" && basketTotalData && !isB2B && basketTotalData.paymentCostAmountGross > 0
  }, [isB2B, basketTotalData])

  function removePromoCode(promoCode: string) {
    removePromoCodeFromCurrentBasketAction(promoCode).then((result) => {
      setBasketData(result.basketData)
    })
  }

  return (
    <>
      {basketData && basketTotalData.totalProductQuantity > 0 ? (
        <div className={"sticky top-[7.5rem] lg:top-[8.5rem]"}>
          <BasicCard className={"min-h-64"}>
            <div className={"flex flex-col gap-2"}>
              <h2 className={"text-lg font-bold"}>{props.blok?.label_promo_code ?? ""}</h2>
              {/* <div className="flex w-full items-center justify-between">
          <Image src="/img/baer_transparent.png" alt="Logo" width={40} height={40}/>
          <span className="text-lg font-bold">
            {basketTotalData.totalProductQuantity ?? ""} {t(`common.${basketTotalData.totalProductQuantity === 1 ? "item" : "items"}`)}
          </span>
        </div>*/}

              {basketData && basketTotalData.totalProductQuantity && basketTotalData.totalProductQuantity > 0 && (
                <div className="grid grid-cols-2 text-sm">
                  {basketData.data?.totals?.itemTotal?.net && (
                    <>
                      <span className={"text-nowrap font-bold"}>Positionssumme (netto)</span>
                      <span className="text-right  font-bold">
                        {formatPrice(isoLocale, basketTotalData.itemTotalNet, basketTotalData.currency)}
                      </span>
                    </>
                  )}

                  {basketTotalData.ecoTaxTotal > 0 && (
                    <>
                      <span className={"text-nowrap"}>{props.blok?.ecoTaxHint ?? ""}</span>
                      <span className="text-right">
                        {<>{formatPrice(isoLocale, basketTotalData.ecoTaxTotal, basketTotalData.currency)}</>}
                      </span>
                    </>
                  )}
                  {basketTotalData.totalSavingsGeneralNet && basketTotalData.totalSavingsGeneralNet > 0.009 ? (
                    <>
                      <span className={"text-nowrap"}>Rahmenrabatt (netto):</span>
                      <span className="text-right font-bold text-green-500">
                        {formatPrice(isoLocale, -1 * basketTotalData.totalSavingsGeneralNet, basketTotalData.currency)}
                      </span>
                    </>
                  ) : (
                    <>
                      <span></span>
                      <span></span>
                    </>
                  )}

                  {basketTotalData.totalSavingsSpecialNet && basketTotalData.totalSavingsSpecialNet > 0.009 ? (
                    <>
                      <span className={"text-nowrap"}>Sonderrabatt (netto):</span>
                      <span className="text-right font-bold text-green-500">
                        {formatPrice(isoLocale, -1 * basketTotalData.totalSavingsSpecialNet, basketTotalData.currency)}
                      </span>
                    </>
                  ) : (
                    <>
                      <span></span>
                      <span></span>
                    </>
                  )}

                  {basketTotalData.promotionCode && basketData.data?.totals?.shippingTotal?.net && (
                    <>
                      <div className={"flex flex-row items-center gap-1"}>
                        <span className={"text-nowrap"}>Gutscheincode: {basketTotalData.promotionCode}</span>
                        <Button
                          onClick={() => removePromoCode(basketTotalData.promotionCode ?? "")}
                          variant={"ghost"}
                          size={"tiny"}
                        >
                          <XCircleIcon className={"size-4"} />
                        </Button>
                      </div>
                      <span className="text-right font-bold text-green-500">
                        {formatPrice(isoLocale, basketTotalData.promotionAmountNet, basketTotalData.currency)}
                      </span>
                    </>
                  )}

                  {basketData.data?.totals?.grandTotal?.net && (
                    <>
                      <span className={"mt-2 text-nowrap font-bold"}>Zwischensumme (netto)</span>
                      <span className="mt-2 text-right font-bold">
                        {formatPrice(isoLocale, basketTotalData.grandTotalNet, basketTotalData.currency)}
                      </span>
                    </>
                  )}

                  <span className="text-nowrap ">Mehrwertsteuer 19%</span>
                  <span className="text-right font-bold">
                    {formatPrice(isoLocale, basketTotalData.totalTax, basketTotalData.currency)}
                  </span>

                  {basketData.data?.totals?.shippingTotal?.net && (
                    <>
                      <span className={"text-nowrap"}>Versandkosten</span>
                      <span className="text-right font-bold text-green-500">
                        {formatPrice(isoLocale, basketTotalData.shippingTotalNet, basketTotalData.currency)}
                      </span>
                    </>
                  )}

                  {!isB2B && basketTotalData.paymentCostAmountGross > 0 && (
                    <>
                      <div className="  text-nowrap ">Abwicklungspauschale</div>
                      <span className="  text-right ">
                        {formatPrice(isoLocale, basketTotalData.paymentCostAmountGross, basketTotalData.currency)}
                      </span>
                    </>
                  )}

                  <div className="mt-2 flex items-center text-nowrap font-bold">Summe der Lieferartikel (brutto)</div>
                  <span className="mt-2 text-right text-lg font-bold text-primary">
                    {formatPrice(isoLocale, basketTotalData.grandTotalGross, basketTotalData.currency)}
                  </span>

                  <span className="text-nowrap ">Gesamtersparnis (brutto)</span>
                  <span className="text-right font-bold text-green-500">
                    {formatPrice(isoLocale, -1 * basketTotalData.totalSavingsGross, basketTotalData.currency)}
                  </span>
                </div>
              )}

              {showHandlingFeeHint && <p className={"text-xs text-gray-400"}>{props.blok?.handlingFeeHint ?? ""}</p>}
            </div>
          </BasicCard>
        </div>
      ) : (
        <Skeleton className="h-96 w-1/3" />
      )}
    </>
  )
}
