import React, { createElement, Fragment, ReactElement } from "react"
import { NODE_PARAGRAPH, render, RenderOptions } from "storyblok-rich-text-react-renderer"

import { getLanguageFromSbLocale } from "@/lib/locale/locale-common-utils"
import { AssetStoryblok, RichtextStoryblok } from "@/components/storyblok/component-types"

interface EmailTemplateProps {
  sbLocale: string
  image?: AssetStoryblok
  body: RichtextStoryblok
  footerLeft: RichtextStoryblok
  footerRight: RichtextStoryblok
  footerBottom: RichtextStoryblok
}

const renderNewLines = (textParts: string[], type: string, props: any) => {
  return (
    <>
      {textParts.map((item, index) => (
        <Fragment key={index}>
          {createElement(type, props, item)}
          {index < textParts.length - 1 && <br />}
        </Fragment>
      ))}
    </>
  )
}

const renderOptions: RenderOptions = {
  nodeResolvers: {
    [NODE_PARAGRAPH]: (children: ReactElement<any>[]) => {
      // todo solve with recoursive function
      const textItemWithNewLine = children.find((child) => child.toString().includes("\n"))
      if (textItemWithNewLine) {
        const textParts: string[] = textItemWithNewLine.toString().split("\n")
        return <p>{renderNewLines(textParts, "span", null)}</p>
      }

      const itemWithNewLine = children.find((child: ReactElement<any>) =>
        child?.props?.children?.toString().includes("\n")
      )
      if (itemWithNewLine) {
        const textParts: string[] = itemWithNewLine.props.children.split("\n")
        return <p>{renderNewLines(textParts, itemWithNewLine.type.toString(), itemWithNewLine.props)}</p>
      }

      const itemWithNewLine2 = children.find((child: ReactElement<any>) =>
        child?.props?.children?.props?.children?.toString().includes("\n")
      )
      if (itemWithNewLine2) {
        const textParts: string[] = itemWithNewLine2.props.children.props.children.split("\n")
        return (
          <p>
            {createElement(
              itemWithNewLine2.type,
              itemWithNewLine2.props,
              renderNewLines(
                textParts,
                itemWithNewLine2.props.children.type.toString(),
                itemWithNewLine2.props.children.props
              )
            )}
          </p>
        )
      }

      const itemWithNewLine3 = children.find((child: ReactElement<any>) =>
        child?.props?.children?.props?.children?.props?.children?.toString().includes("\n")
      )
      if (itemWithNewLine3) {
        const textParts: string[] = itemWithNewLine3.props.children.props.children.props.children.split("\n")
        return (
          <p>
            {createElement(
              itemWithNewLine3.type,
              itemWithNewLine3.props,
              createElement(
                itemWithNewLine3.props.children.type,
                itemWithNewLine3.props.children.props,
                renderNewLines(
                  textParts,
                  itemWithNewLine3.props.children.props.children.type.toString(),
                  itemWithNewLine3.props.children.props.children.props
                )
              )
            )}
          </p>
        )
      }

      return <p>{children}</p>
    },
  },
}

const EmailTemplateContact = (props: EmailTemplateProps) => {
  const language = getLanguageFromSbLocale(props.sbLocale).toUpperCase()

  return (
    <html>
      <body
        lang={language}
        style={{
          background: "#D8D8D8",
          fontFamily: "Arial",
        }}
      >
        <table style={{ borderSpacing: 0, borderCollapse: "collapse", width: "450.0pt" }}>
          <tbody>
            {props.image && (
              <tr>
                <td style={{ background: "white", padding: "15.0pt 15.0pt 15.0pt 15.0pt" }}>
                  <img src={props.image.filename ?? ""} alt={props.image.alt ?? ""} />
                </td>
              </tr>
            )}
            <tr>
              <td style={{ background: "#F5F5F5", padding: "30.0pt 22.5pt 60.0pt 22.5pt", lineHeight: "16.5pt" }}>
                <div>{render(props.body, renderOptions)}</div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  background: "#7D7D7D",
                  padding: "30.0pt 22.5pt 60.0pt 22.5pt",
                }}
              >
                <table
                  style={{
                    borderSpacing: 0,
                    borderCollapse: "collapse",
                    width: "100%",
                    fontSize: "9.0pt",
                    lineHeight: "13.5pt",
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ verticalAlign: "top" }}>{render(props.footerLeft)}</td>
                      <td style={{ verticalAlign: "top" }}>{render(props.footerRight)}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  background: "#333333",
                  padding: "11.25pt 22.5pt 11.25pt 22.5pt",
                  fontSize: "9.0pt",
                }}
              >
                {render(props.footerBottom)}
              </td>
            </tr>
          </tbody>
        </table>
      </body>
    </html>
  )
}

export default EmailTemplateContact
