"use client"

import React, { useState } from "react"
import { addArticleToCurrentBasketAction } from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { ShoppingCart } from "lucide-react"

import { Button, Input } from "@/components/ui/components"
import { UbBasketQuickOrderStoryblok } from "@/components/storyblok/component-types"

interface BasketQuickOrderProps {
  blok: UbBasketQuickOrderStoryblok
}

export default function BasketQuickOrder(props: BasketQuickOrderProps) {
  const [productId, setProductId] = useState<string | undefined>(undefined)

  const { setBasketData, setIsLoading } = useUbStorefrontState()

  function updateBasketData(productId: string) {
    setIsLoading(true)
    if (productId) {
      addArticleToCurrentBasketAction(productId, 1).then((basketData) => {
        setBasketData(basketData)
        setIsLoading(false)
      })
    }
  }

  const handleChange = (event) => {
    const { value } = event.target
    setProductId(value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (productId && productId !== "") {
      updateBasketData(productId)
    }
  }

  return (
    <div className={"min-h-32"}>
      <div className={"flex flex-col gap-2"}>
        <h2 className={"text-lg font-bold"}>{props.blok["label_quick_order"]}</h2>
        <form className={"flex flex-row items-center gap-2"} onSubmit={handleSubmit}>
          <Input type="text" name="productId" value={productId} onChange={handleChange} />

          <Button size={"productCardIcon"} onClick={handleSubmit}>
            <ShoppingCart className={"size-4"} />
          </Button>
        </form>
      </div>
    </div>
  )
}
