// returns e.g. "at" (lower case)
import { Locales } from "@/lib/link-utils/constants.cjs"
import { logger } from "@/lib/logger"

export function getCountry(isoLocale: string): string {
  const country = isoLocale.split("-")[1] // will always have at least 2 parts
  return country.toLowerCase()
}

// returns "EUR" or "CHF" (in ch)
export function getCurrency(isoLocale: string): string {
  return getCountry(isoLocale) === "ch" ? "CHF" : "EUR"
}

export function getLanguageFromIsoLocale(isoLocale: string): string {
  const language = isoLocale.split("-")[0] // will always have at least 2 parts
  return language.toLowerCase()
}

// returns e.g. "de-AT"
export function getIsoLocaleFromSbLocale(sbLocale: string): string {
  const isoLocale = Locales.sbLocaleToIsoLocale[sbLocale]
  if (!isoLocale) {
    logger.warn(`getIsoLocaleFromSbLocale(): Internal error: no isoLocale found for sbLocale ${sbLocale}.`)
    return Locales.sbLocaleToIsoLocale[Locales.defaultSbLocale]
  }
  return isoLocale
}

// returns e.g. "de" for "de-CH" (lower case)
export function getLanguageFromSbLocale(sbLocale: string): string {
  const isoLocale = getIsoLocaleFromSbLocale(sbLocale)
  const language = isoLocale.split("-")[0] // will always have at least 2 parts
  return language.toLowerCase()
}
