"use client"

import React, { useEffect, useLayoutEffect } from "react"
import { useRouter, useSearchParams } from "next/navigation"
import { useUbPdpState } from "@/store/UbpdpState"
import { useUbStorefrontState } from "@/store/ubStorefrontState"

import { generateProductLink } from "@/lib/link-utils/link-utils"
import { PdpContext } from "@/lib/storefront/product/pdp-service"
import { UseFilteredVariantsHook } from "@/components/product/pdp/UseFilteredVariantsHook"

export interface FilterNavigationProps {
  pdpContext: PdpContext
}

export function FilterNavigation(props: FilterNavigationProps) {
  const { filterClicked, setFilterClicked } = useUbPdpState()
  const { setIsLoading } = useUbStorefrontState()

  const masterProduct = props.pdpContext.productAndArticle.masterProduct

  const filteredVariants = UseFilteredVariantsHook(masterProduct).filteredVariants

  const searchParams = useSearchParams()
  const router = useRouter()

  const { setSelectedFilterOptions, setUseClientSelectedFilterOptions } = useUbPdpState()

  useLayoutEffect(() => {
    const selectedFilterOptions = props.pdpContext.selectedFilterOptions
    setSelectedFilterOptions(selectedFilterOptions)
    setUseClientSelectedFilterOptions(true)
  }, [props.pdpContext.selectedFilterOptions, setSelectedFilterOptions, setUseClientSelectedFilterOptions])

  useEffect(() => {
    if (filteredVariants.length === 1 /*&& props.pdpContext.pdpViewMode === "MASTER_PRODUCT"*/ && filterClicked) {
      const targetUrl = generateProductLink(
        filteredVariants[0].title,
        filteredVariants[0].sku,
        searchParams,
        props.pdpContext.sbLocale
      )

      //window.location.replace(targetUrl)
      //window.history.pushState(null, '', targetUrl)
      setFilterClicked(false)
      //console.log("load article")
      setIsLoading(true)
      router.push(targetUrl)
    } else {
      if (filteredVariants.length > 1 && props.pdpContext.pdpViewMode !== "MASTER_PRODUCT" && filterClicked) {
        /*        const targetUrl =
          generateProductLinkHelper(masterProduct?.title, masterProduct?.id) + `?${searchParams.toString()}`
        //window.location.replace(targetUrl)
        //window.history.pushState(null, '', targetUrl)
        setFilterClicked(false)
        console.log("load master")
        setIsLoading(true)
        router.push(targetUrl)*/
      }
    }
  }, [
    filterClicked,
    filteredVariants,
    masterProduct,
    props.pdpContext.pdpViewMode,
    props.pdpContext.sbLocale,
    router,
    searchParams,
    setFilterClicked,
    setIsLoading,
  ])

  return <div className={"hidden"}></div>
}
