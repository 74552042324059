import { createQuoteRequestAction } from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { StoryblokComponent } from "@storyblok/react/rsc"
import { QuoteRequestRO } from "@ub/quoting-client"
import { FileDown, NotebookPenIcon } from "lucide-react"

import { UserData } from "@/lib/intershop/interfaces/user/user"
import { BasketLineItemData } from "@/lib/storefront/basket/basket"
import { Button } from "@/components/ui/components"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/components/tooltip"
import { UbBasketLineItemsStoryblok } from "@/components/storyblok/component-types"

type BasketActionsProps = {
  blok: UbBasketLineItemsStoryblok
  isB2B: boolean
  customerData?: UserData
  userData?: UserData | undefined
  basketLineItems: BasketLineItemData[] | undefined
}

export default function BasketActions(props: BasketActionsProps) {
  const { setIsLoading } = useUbStorefrontState()

  function createQuoteRequest() {
    setIsLoading(true)
    createQuoteRequestAction(props.customerData, props.userData).then((result: QuoteRequestRO) => {
      console.dir(result, { depth: 9 })
      setIsLoading(false)
    })
  }

  return (
    <div className={"flex flex-row flex-wrap justify-start gap-2"}>
      {props.basketLineItems && (
        <>
          {props.isB2B && props.blok && (
            <a className={""} href={"/" + props.blok["save_pdf_path"]}>
              <Button className={""} variant={"outline_secondary"} size={"defaultNoGrow"}>
                <div className={"flex w-full flex-row items-center justify-between gap-1"}>
                  <p>{props.blok["label_save_as_pdf"]}</p>
                  <FileDown />
                </div>
              </Button>
            </a>
          )}

          {props.isB2B && (
            <>
              {props.userData ? (
                <>
                  <Button className={""} variant={"outline_secondary"} size={"defaultNoGrow"}>
                    <div className={"flex flex-row items-center justify-between gap-1"} onClick={createQuoteRequest}>
                      <p>{props.blok["label_request_quote"]}</p>
                      <NotebookPenIcon />
                    </div>
                  </Button>
                </>
              ) : (
                <>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button className={"grow justify-between"} variant={"outline_secondary"} size={"defaultNoGrow"}>
                          <p>{props.blok["label_create_quote"]}</p>
                          <NotebookPenIcon />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <div>
                          <StoryblokComponent blok={props.blok["tooltip_content"]} />
                        </div>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}
