"use client"

import React, { useEffect, useState } from "react"

import { PdpContext } from "@/lib/storefront/product/pdp-service"
import { cn } from "@/components/ui/helpers/utils"
import { UseFilteredVariantsHook } from "@/components/product/pdp/UseFilteredVariantsHook"
import { UbProductDetailViewStoryblok } from "@/components/storyblok/component-types"

function AnchorNavigationItem(props: { title: string; targetAnchorId: string; active: boolean }) {
  return (
    <div
      onClick={() => {
        const element = document.getElementById(props.targetAnchorId)
        element?.scrollIntoView({
          behavior: "smooth",
        })
      }}
      className={cn(
        "flex w-auto cursor-pointer flex-col justify-end border-b-4 border-b-transparent py-0 text-lg font-bold sm:pb-2",
        {
          "border-b-primary": props.active,
        }
      )}
    >
      <p>{props.title}</p>
    </div>
  )
}

interface ProductDetailAnchorNavigationProps {
  pdpContext: PdpContext
  anchorIds: string[]
  children?: typeof React.Children
  blok: UbProductDetailViewStoryblok
}

export default function ProductDetailAnchorNavigation({
  pdpContext,
  anchorIds,
  blok,
}: ProductDetailAnchorNavigationProps) {
  const masterProduct = pdpContext.productAndArticle.masterProduct
  const accessories = pdpContext.accessories
  const filteredVariants = UseFilteredVariantsHook(masterProduct).filteredVariants
  const [activeSection, setActiveSection] = useState("productConfigurationAnchor")
  const [scrollDirection, setScrollDirection] = useState<"up" | "down">("down")

  useEffect(() => {
    let lastScrollY = window.scrollY

    const updateScrollDirection = () => {
      const scrollY = window.scrollY
      if (scrollY > lastScrollY) {
        setScrollDirection("down")
      } else if (scrollY < lastScrollY) {
        setScrollDirection("up")
      }
      lastScrollY = scrollY
    }

    window.addEventListener("scroll", updateScrollDirection)

    return () => {
      window.removeEventListener("scroll", updateScrollDirection)
    }
  }, [])

  useEffect(() => {
    const callback = (entries: IntersectionObserverEntry[]) => {
      const intersectingElements = entries.filter((entry) => entry.isIntersecting)
      if (!intersectingElements.length) {
        return
      }

      const indexToSelect = scrollDirection === "down" ? intersectingElements.length - 1 : 0
      const mostVisibleElement = intersectingElements[indexToSelect]
      if (activeSection !== mostVisibleElement.target.id) {
        setActiveSection(mostVisibleElement.target.id)
      }
    }

    const observer = new IntersectionObserver(callback, {
      root: null,
      rootMargin: "-30% 0px -60% 0px",
      threshold: 0,
    })

    const elements = anchorIds.map((id) => document.getElementById(id)).filter(Boolean) as Element[]

    elements.forEach((el) => {
      observer.observe(el)
    })

    return () => {
      elements.forEach((el) => {
        observer.unobserve(el)
      })
    }
  }, [anchorIds, scrollDirection, activeSection])

  return (
    <div className={"sticky z-50 h-auto bg-ub-background md:top-14 lg:top-[106px]"}>
      <div
        className={
          "mt-0 hidden min-h-20 flex-row flex-wrap gap-x-6 gap-y-2 px-8 sm:flex sm:gap-x-8 lg:justify-start lg:gap-x-20"
        }
      >
        <AnchorNavigationItem
          title={blok?.label_product_configuration}
          targetAnchorId={"productConfigurationAnchor"}
          active={activeSection === "productConfigurationAnchor"}
        />

        {accessories?.length > 0 && (
          <AnchorNavigationItem
            title={blok?.label_accessories}
            targetAnchorId={"accessoriesTableAnchor"}
            active={activeSection === "accessoriesTableAnchor"}
          />
        )}

        {filteredVariants?.length > 1 && (
          <AnchorNavigationItem
            title={blok?.label_variants}
            targetAnchorId={"variantTableAnchor"}
            active={activeSection === "variantTableAnchor"}
          />
        )}

        <AnchorNavigationItem
          title={blok?.label_technical_data}
          targetAnchorId={"attributeGridAnchor"}
          active={activeSection === "attributeGridAnchor"}
        />

        <AnchorNavigationItem
          title={blok?.label_product_description}
          targetAnchorId={"productDescriptionAnchor"}
          active={activeSection === "productDescriptionAnchor"}
        />

        {(pdpContext.productAndArticle.article?.safetyDataSheet ||
          (pdpContext.productAndArticle.article?.dataSheets &&
            pdpContext.productAndArticle.article?.dataSheets.length > 0)) && (
          <AnchorNavigationItem
            title={blok?.label_downloads}
            targetAnchorId={"productDownloadsAnchor"}
            active={activeSection === "productDownloadsAnchor"}
          />
        )}
      </div>
    </div>
  )
}
