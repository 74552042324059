"use client"

import React, { PropsWithChildren, useState } from "react"

import { cn } from "@/components/ui/helpers/utils"
import { useClickOutside } from "@/components/ui/hooks/click-outside-hook"

interface ProductDetailBuyboxShipmentHintProps extends PropsWithChildren {
  hintLabel: string
}

const ProductDetailBuyboxShipmentHint = (props: ProductDetailBuyboxShipmentHintProps) => {
  const [popupVisible, setPopupVisible] = useState(false)
  const ref = useClickOutside<HTMLDivElement>(() => setPopupVisible(false))

  return (
    <div className="relative" ref={ref}>
      <button
        className="font-semibold text-gray2 underline hover:text-primary hover:no-underline"
        onClick={() => setPopupVisible(!popupVisible)}
      >
        {props.hintLabel}
      </button>
      <div
        className={cn("absolute bottom-8 right-0 w-max max-w-none border drop-shadow-md ", {
          block: popupVisible,
          hidden: !popupVisible,
        })}
      >
        {props.children}
      </div>
    </div>
  )
}

export default ProductDetailBuyboxShipmentHint
