"use client"

import { useTranslations } from "next-intl"

import LoginForm from "@/components/auth/LoginForm"
import { UBHeaderProps } from "@/components/storyblok/UBHeader"

export default function UbLoginForm({ blok }: UBHeaderProps) {
  const t = useTranslations("storefront")

  //console.dir(blok)

  return (
    <div className="w-full">
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-col border-gray-700 lg:basis-1/2 lg:border-r lg:pr-6">
          <h2 className="mb-2 text-center text-2xl font-bold leading-tight">
            {blok.title || t("account.form.account_headline")}
          </h2>
          <h3 className="mb-5 text-center text-xl leading-tight text-gray-400">
            {blok.subtitle || t("account.form.account_text")}
          </h3>

          <LoginForm forgotPasswordUrl={blok?.forgot_password_url?.cached_url ?? ""} />
        </div>
        <div className="visible flex py-4 lg:hidden">
          <div className="my-auto h-px grow bg-gray-300"></div>
          <span className="mx-2 text-gray-500"> {t("account.form.or")}</span>
          <div className="my-auto h-px grow bg-gray-300"></div>
        </div>

        <div className="flex grow flex-col justify-center lg:basis-1/2 lg:pl-6">
          <div className="mb-3.5">
            <h2 className="mb-2 text-center text-2xl font-bold leading-tight">
              {t("account.form.no_account_headline")}
            </h2>
            <h3 className="mb-3.5 text-center text-xl leading-tight text-gray-600">
              {t("account.form.no_account_text")}
            </h3>
          </div>
          <a
            href={blok.register_url?.cached_url}
            className="text-md cursor-pointer rounded border-2 border-primary bg-white py-2 text-center font-bold text-gray-800 hover:bg-primary hover:text-white"
          >
            {t("account.form.register")}
          </a>
        </div>
      </div>
    </div>
  )
}
