"use client"

import React, { Suspense, useEffect, useMemo } from "react"
import Link from "next/link"
import { useRouter, useSearchParams } from "next/navigation"
import {
  createOrderFromCurrentBasketAction,
  loadBasketLineItemsAction,
  loadBasketPaymentsAction,
  loadBasketPromotionCodesAction,
  loadCurrentBasketAction,
  updateOrderAfterRedirectAction,
} from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { useTranslations } from "next-intl"

import { BasketContext } from "@/lib/storefront/basket/basket"
import { CheckoutContext } from "@/lib/storefront/checkout/checkout-service"
import { Button, Input, Label, Textarea } from "@/components/ui/components"
import { AddressCard } from "@/components/addresses/AddressCard"
import BasketPromoCode from "@/components/basket/BasketPromoCode"
import { BasketLineItemList } from "@/components/basket/overview/BasketLineItemList"
import BasicCard from "@/components/common/BasicCard"

interface CheckoutReviewProps {
  checkoutContext: CheckoutContext
  basketContext: BasketContext
}

const MAX_COMMENT_LENGTH = 1000

function CheckoutAdditionalOrderData() {
  const { customOrderId, setCustomOrderId, messageToMerchant, setMessageToMerchant } = useUbStorefrontState()

  function changeCustomOrderId(event) {
    const { value } = event.target
    setCustomOrderId(value)
  }

  function changeMessageToMerchant(event) {
    const { value } = event.target
    if (value && value.length <= MAX_COMMENT_LENGTH) {
      setMessageToMerchant(value)
    }
  }

  return (
    <>
      <div className={"flex flex-col gap-2"}>
        <h2 className={"text-lg font-bold"}>Zusätzliche Angaben</h2>
        <div className={"flex flex-col gap-2"}>
          <div>
            <Label htmlFor={"internalOrderId"}>Bestell-ID</Label>
            <Input type="text" name="internalOrderId" value={customOrderId} onChange={changeCustomOrderId} />
            <p className={"text-sm text-secondary-foreground"}>
              Sie können eine ID für ihre eigene Buchhaltung eingeben
            </p>
          </div>

          <div>
            <Label htmlFor={"internalOrderComment"}>Nachricht an UDOBÄR</Label>
            <Textarea name="internalOrderComment" value={messageToMerchant} onChange={changeMessageToMerchant} />
            <p className={"text-sm text-secondary-foreground"}>
              Ihre Nachricht: {MAX_COMMENT_LENGTH - messageToMerchant.length} Zeichen verbleibend
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default function CheckoutReview(props: CheckoutReviewProps) {
  const t = useTranslations("storefront")
  const { setCreatedOrder } = useUbStorefrontState()

  const checkoutContext = props.checkoutContext
  const isB2B = props.checkoutContext.isB2B
  const router = useRouter()
  const searchParams = useSearchParams()

  const {
    basketData,
    setBasketData,
    basketLineItems,
    setBasketLineItems,
    setBasketPromotionCodes,
    currentPayment,
    setCurrentPayment,
    customOrderId,
    messageToMerchant,
  } = useUbStorefrontState()

  const payment = useMemo(() => {
    if (currentPayment && currentPayment.length) {
      return currentPayment[0]
    }
  }, [currentPayment])

  useEffect(() => {
    const redirect = searchParams.get("redirect")
    const orderId = searchParams.get("orderId")
    if (redirect && orderId) {
      const params: { [key: string]: string } = {}
      searchParams.forEach((value, key) => {
        params[key] = value
      })
      updateOrderAfterRedirectAction(orderId, params).then((value) => {
        if ("success" === redirect) {
          router.push("/basket/checkout/confirmation")
        } else {
          console.error(value?.message)
        }
      })
    }
  }, [searchParams, router])

  useEffect(() => {
    console.dir(basketLineItems)
    console.dir(basketData)

    if (!basketData) {
      loadCurrentBasketAction().then((basketData) => {
        if (basketData) {
          setBasketData(basketData)
        }
      })
    }
  }, [basketData, basketLineItems])

  useEffect(() => {
    if (basketData) {
      loadBasketLineItemsAction(
        checkoutContext.isoLocale,
        checkoutContext.isB2B,
        checkoutContext.userData,
        basketData
      ).then((lineItems) => {
        if (lineItems) {
          lineItems.sort((a, b) => (a.position > b.position ? 1 : -1))
          setBasketLineItems(lineItems)
        }
      })
    }
  }, [basketData, checkoutContext.isB2B, checkoutContext.userData, setBasketLineItems])

  useEffect(() => {
    if (basketData) {
      loadBasketPromotionCodesAction().then((promotionCodes) => {
        if (promotionCodes) {
          setBasketPromotionCodes(promotionCodes)
        }
      })
    }
  }, [basketData, checkoutContext.isB2B, setBasketPromotionCodes])

  useEffect(() => {
    if (basketData) {
      loadBasketPaymentsAction().then((payments) => {
        if (payments) {
          setCurrentPayment(payments)
        }
      })
    }
  }, [basketData, checkoutContext.isB2B, setBasketPromotionCodes])

  function createOrder() {
    createOrderFromCurrentBasketAction(customOrderId, messageToMerchant).then((value) => {
      const createdOrder = value?.orderData
      console.dir(createdOrder, { depth: 10 })
      if (createdOrder) {
        setCreatedOrder(createdOrder)
        router.push("/basket/checkout/confirmation")
      } else {
        console.error(value?.message)
      }
    })
  }

  return (
    <>
      {props.checkoutContext.userData && (
        <>
          <div className={"grid w-full grid-cols-1 gap-2 lg:grid-cols-3"}>
            <div className={"col-span-2"}>
              <div className={"flex flex-col gap-2"}>
                <p>
                  Prüfen Sie die Details Ihrer Bestellung und nehmen Sie, falls notwendig, noch Änderungen vor. Klicken
                  Sie auf &#34;Bestellung absenden&#34;, um den Bestellvorgang abzuschließen.
                </p>

                <div className={"flex w-full flex-col gap-12"}>
                  <BasicCard className={"sticky top-[7.5rem] min-h-48 lg:top-[8.5rem]"}>
                    <h2 className={"text-lg font-bold"}>Adressen</h2>
                    <div className={"flex w-full flex-col justify-between gap-12 sm:flex-row"}>
                      <div className={"flex w-full flex-row justify-between gap-2"}>
                        <AddressCard address={checkoutContext.addresses[0]} />
                        <Link className={"text-sm"} prefetch={false} href={"/basket/checkout/addresses"}>
                          Bearbeiten
                        </Link>
                      </div>
                      <div className={"flex w-full flex-row justify-between gap-2"}>
                        <AddressCard address={checkoutContext.addresses[1]} />
                        <Link className={"text-sm"} prefetch={false} href={"/basket/checkout/addresses"}>
                          Bearbeiten
                        </Link>
                      </div>
                    </div>
                  </BasicCard>
                </div>

                <div className={"flex w-full flex-col gap-2"}>
                  <BasicCard className={"sticky top-[7.5rem] min-h-28 lg:top-[8.5rem]"}>
                    <h2 className={"text-lg font-bold"}>Zahlungsart</h2>
                    <div className={"flex w-full flex-row justify-between gap-2"}>
                      <Suspense fallback={<div>...</div>}>
                        {payment?.paymentMethod ? (
                          <div className={"flex flex-col gap-2"}>
                            {t("checkout.payment.methods." + payment?.paymentMethod)}
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </Suspense>
                      <Link className={"text-sm"} prefetch={false} href={"/basket/checkout/payment"}>
                        Bearbeiten
                      </Link>
                    </div>
                  </BasicCard>
                </div>

                <div className={"flex w-full flex-col gap-2"}>
                  <BasicCard className={"sticky top-[7.5rem] min-h-48 lg:top-[8.5rem]"}>
                    <h2 className={"text-lg font-bold"}>Prüfen und Kaufen</h2>
                    <div className={"flex w-full flex-row justify-between gap-2"}>
                      {basketData &&
                      (basketData?.data?.totalProductQuantity ?? 0) > 0 &&
                      basketLineItems &&
                      basketLineItems.length > 0 ? (
                        <>
                          <div className={"flex flex-col gap-2"}>
                            <BasketLineItemList isB2B={isB2B} basketLineItems={basketLineItems} />
                          </div>
                        </>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </BasicCard>
                </div>

                <div>
                  <BasketPromoCode />
                </div>
                <div>
                  <CheckoutAdditionalOrderData />
                </div>

                <Button
                  onClick={createOrder}
                  size={"defaultNoGrow"}
                  variant={"default"}
                  className={"bg-primary py-0 font-normal text-white"}
                >
                  {"Kostenpflichtig Bestellen"}
                </Button>

                <div>
                  {props.checkoutContext?.checkoutContent?.data?.story && (
                    <div>{JSON.stringify(props.checkoutContext?.checkoutContent?.data?.story, null, 4)}</div>
                  )}
                </div>
              </div>
            </div>
            <div className={"col-span-1"}>
              <BasicCard className={"sticky top-[7.5rem] min-h-64 lg:top-[8.5rem]"}>
                {/*     <BasketSummary
                  basketContext={props.basketContext}
                  blok={undefined} //TODO remove and make BasketSummary usable in SB here
                />*/}
              </BasicCard>
            </div>
          </div>
        </>
      )}
    </>
  )
}
