"use client"

import React, { forwardRef } from "react"
import Image, { ImageProps } from "next/image"

const ImageWithFallback = forwardRef<HTMLImageElement, ImageProps & { src?: string }>(
  ({ src, alt, width, height, ...restProps }, ref) => {
    return (
      <Image
        ref={ref}
        src={src || "/img/not_available.png"}
        alt={alt}
        width={width || 758}
        height={height || 758}
        {...restProps}
        onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
          event.currentTarget.id = "/img/not_available.png"
          event.currentTarget.srcset = "/img/not_available.png"
        }}
      />
    )
  }
)

ImageWithFallback.displayName = "ImageWithFallback"

export default ImageWithFallback
