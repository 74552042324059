"use client"

import { useMemo } from "react"
import Link from "next/link"
import { useUbStorefrontState } from "@/store/ubStorefrontState"

import { FacetElementData } from "@/lib/factfinder/factfinder-interfaces"
import { formatAndReplacePrices } from "@/lib/helper"
import { getLinkWithEncryptedFfSearchParams } from "@/lib/link-utils/link-utils"
import { Category } from "@/lib/storefront/category/category"

interface FacetElementProps {
  facetElementData: FacetElementData
  query?: string
  baseCategory?: Category
  brandSlug?: string
  unit?: string
  isoLocale: string
}

export default function FacetElement(props: FacetElementProps) {
  const hitcount = props.facetElementData.hitcount
  const elementname = props.facetElementData.elementname
  const selected = props.facetElementData.selected
  const isoLocale = props.isoLocale

  const { setIsLoading } = useUbStorefrontState()

  const link = useMemo(() => {
    return getLinkWithEncryptedFfSearchParams(
      props.query,
      props.baseCategory,
      props.brandSlug,
      props.facetElementData.encryptedFfSearchParams,
      props.isoLocale
    )
  }, [
    props.baseCategory,
    props.brandSlug,
    props.facetElementData.encryptedFfSearchParams,
    props.isoLocale,
    props.query,
  ])

  return (
    <Link prefetch={false} href={link} onClick={() => setIsLoading(true)} data-locale={props.isoLocale}>
      <div className={"flex justify-between"}>
        <div className="flex min-w-40 flex-row items-center gap-1 transition duration-200 ease-in-out hover:translate-x-1 hover:text-primary">
          <input
            aria-label={"filter_" + elementname}
            className={"checked:bg-primary hover:checked:bg-primary focus:ring-0"}
            name={"filter_" + elementname}
            type="checkbox"
            defaultChecked={selected}
          />

          <label htmlFor={"filter_" + elementname}>
            {formatAndReplacePrices(isoLocale, elementname) + " " + (props.unit ?? "")}
          </label>
        </div>
        <p
          className={
            "max-h-[1.35rem] rounded-sm border border-gray-300 px-[0.2rem] text-[0.6rem] font-bold text-gray-700"
          }
        >
          {hitcount}
        </p>
      </div>
    </Link>
  )
}
