"use client"

import React from "react"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { useTranslations } from "next-intl"

import { Button } from "@/components/ui/components"
import { cn } from "@/components/ui/helpers/utils"

interface FacetApplyFiltersButtonProps {
  hitcount: number
}

export default function FacetApplyFiltersButton(props: FacetApplyFiltersButtonProps) {
  const t = useTranslations("storefront")
  const { filterShown, setFilterShown } = useUbStorefrontState()

  function handleCloseFilter() {
    setFilterShown(false)
  }
  return (
    <div
      className={cn(
        "fixed inset-x-0 bottom-0 hidden h-32 flex-col items-center justify-center gap-2 border-t border-t-gray-200 bg-white px-8 ",
        {
          "flex z-[300] lg:hidden": filterShown,
        }
      )}
    >
      <Button className={"w-full"} size={"lg"} onClick={handleCloseFilter}>
        {t("common.apply_filters", { hitcount: props.hitcount })}
      </Button>
    </div>
  )
}
