"use client"

import React, { useState } from "react"
import { addPromoCodeToCurrentBasketAction } from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"

import { Button, Input } from "@/components/ui/components"

interface BasketPromoCodeProps {
  blok?: any
}

export default function BasketPromoCode(props: BasketPromoCodeProps) {
  const [promoCode, setPromoCode] = useState<string | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const { basketData, basketLineItems, setBasketData, setIsLoading } = useUbStorefrontState()

  function addPromoCode(promoCode: string) {
    if (promoCode) {
      setIsLoading(true)

      addPromoCodeToCurrentBasketAction(promoCode).then((result) => {
        setBasketData(result.basketData)
        if (result.result === "NOK") {
          setErrorMessage(result.message)
        }
        setIsLoading(false)
      })
    }
  }

  const handleChange = (event) => {
    const { value } = event.target
    setPromoCode(value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrorMessage(undefined)
    if (promoCode && promoCode !== "") {
      addPromoCode(promoCode)
    }
  }

  return (
    <>
      {basketData &&
        (basketData?.data?.totalProductQuantity ?? 0) > 0 &&
        basketLineItems &&
        basketLineItems.length > 0 && (
          <div className={"min-h-32"}>
            <>
              <div className={"flex flex-col gap-2"}>
                <h2 className={"text-lg font-bold"}>{props.blok?.label_promo_code ?? "Rabattcode"}</h2>
                <form className={"flex flex-row items-center gap-2"} onSubmit={handleSubmit}>
                  <Input type="text" name="productId" value={promoCode} onChange={handleChange} />

                  <Button size={"productCard"} onClick={handleSubmit}>
                    {props.blok?.label_add_promo_code ?? "Rabattcode einlösen"}{" "}
                  </Button>
                </form>
                {errorMessage && <p className={"text-xs text-red-500"}>{errorMessage}</p>}
              </div>
            </>
          </div>
        )}
    </>
  )
}
