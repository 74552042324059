"use client"

import React from "react"
import { useUbPdpState } from "@/store/UbpdpState"
import { ChevronDown } from "lucide-react"
import { useTranslations } from "next-intl"

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/components"

export function VariantPriceSortDirection() {
  const t = useTranslations("storefront")
  const { variantSortAsc, setVariantSortAsc } = useUbPdpState()

  function handleOnSelect(newVariantSortAsc: boolean) {
    setVariantSortAsc(newVariantSortAsc)
  }

  return (
    <div className={"flex flex-col gap-2 pb-2"}>
      <span className={"font-bold"}>{t("product.variant_price_sort")}</span>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button size={"defaultNoGrow"} variant={"default"} className={"bg-primary py-0 font-normal text-white"}>
            {variantSortAsc ? t("product.variant_price_sort_asc") : t("product.variant_price_sort_desc")}
            <ChevronDown className="-me-1 ms-2 opacity-60" size={16} strokeWidth={2} aria-hidden="true" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="min-w-[--radix-dropdown-menu-trigger-width]">
          <DropdownMenuItem onSelect={() => handleOnSelect(true)}>
            <span>{t("product.variant_price_sort_asc")}</span>
          </DropdownMenuItem>
          <DropdownMenuItem onSelect={() => handleOnSelect(false)}>
            <span>{t("product.variant_price_sort_desc")}</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <div className={"flex flex-row flex-wrap gap-2"}></div>
    </div>
  )
}
