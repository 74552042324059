"use client"

import { FacetElementData } from "@/lib/factfinder/factfinder-interfaces"
import { formatAndReplacePrices } from "@/lib/helper"
import { getLinkWithEncryptedFfSearchParams } from "@/lib/link-utils/link-utils"
import { Category } from "@/lib/storefront/category/category"
import { cn } from "@/lib/utils"
import { DropdownMenuItem } from "@/components/ui/components"

interface CustomDropdownFacetElementProps {
  facetElementData: FacetElementData
  allFacetElements: FacetElementData[] | undefined
  query?: string
  baseCategory?: Category
  brandSlug?: string
  unit?: string
  handleSelect: (allElemData: FacetElementData[], currentElemData: FacetElementData, link: string) => void
  isoLocale: string
  fieldName: string
}

export default function CustomDropdownFacetElement(props: CustomDropdownFacetElementProps) {
  const elementname = props.facetElementData.elementname
  const selected = props.facetElementData.selected

  return (
    <DropdownMenuItem
      key={props.facetElementData.elementname + props.fieldName}
      disabled={props.facetElementData.hitcount < 1}
      onClick={() => {
        props.handleSelect(
          props.allFacetElements ?? [],
          props.facetElementData,
          getLinkWithEncryptedFfSearchParams(
            props.query,
            props.baseCategory,
            props.brandSlug,
            props.facetElementData.encryptedFfSearchParams,
            props.isoLocale
          )
        )
      }}
      className={cn("", { "bg-primary text-primary-foreground": props.facetElementData.selected })}
    >
      <div className={"flex w-full justify-between"}>
        <div className="flex min-w-40 w-full flex-row justify-between items-center gap-1 ">
          <div className={cn("w-full")}>
            {formatAndReplacePrices(props.isoLocale, elementname) + " " + (props.unit ?? "")}
          </div>
          <p
            className={cn(
              "max-h-[1.35rem] rounded-sm border border-gray-300 px-[0.2rem] text-[0.6rem] font-bold text-gray-700",
              {
                hidden: props.facetElementData.hitcount === 0,
                "text-white": props.facetElementData.selected,
              }
            )}
          >
            {props.facetElementData.hitcount}
          </p>
        </div>
      </div>
    </DropdownMenuItem>
  )
}
