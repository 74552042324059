import { FormEvent, useEffect, useState } from "react"
import { getApiToken, mergeCurrentBasket } from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { Check, EyeIcon, EyeOffIcon, XIcon } from "lucide-react"
import { useTranslations } from "next-intl"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/components"

interface LoginFormProps {
  forgotPasswordUrl: string
}

export default function LoginForm(props: LoginFormProps) {
  const [showPassword, setShowPassword] = useState(false)
  const [emailError, setEmailError] = useState<string | undefined>(undefined)
  const [isInitial, setIsInitial] = useState<boolean>(true)
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined)
  const {
    isLoading,
    setIsLoading,
    loginFormUsername,
    setLoginFormUsername,
    loginFormPassword,
    setLoginFormPassword,
    basketData,
    setBasketData,
  } = useUbStorefrontState()
  const t = useTranslations("storefront")

  let passwordInputRef: HTMLInputElement | undefined = undefined
  let usernameInputRef: HTMLInputElement | undefined = undefined

  const usernameRef = (element: HTMLInputElement) => {
    if (element && !!loginFormUsername) {
      element.value = loginFormUsername
      if (!(!!emailError || !!passwordError)) {
        element.dispatchEvent(new Event("input", { bubbles: true }))
      }
    }
    if (isInitial) {
      usernameInputRef = element
    }
  }

  const passwordRef = (element: HTMLInputElement) => {
    if (element && !!loginFormPassword) {
      element.value = loginFormPassword
      if (!(!!emailError || !!passwordError)) {
        element.dispatchEvent(new Event("input", { bubbles: true }))
      }
    }
    if (isInitial) {
      passwordInputRef = element
    }
  }

  useEffect(() => {
    if (
      isInitial &&
      usernameInputRef &&
      loginFormUsername === undefined &&
      usernameInputRef.value &&
      usernameInputRef.value !== ""
    ) {
      setLoginFormUsername(usernameInputRef.value)
    }
    if (
      isInitial &&
      passwordInputRef &&
      loginFormPassword === undefined &&
      passwordInputRef.value &&
      passwordInputRef.value !== ""
    ) {
      setLoginFormPassword(passwordInputRef.value)
    }
  }, [])

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const validateEmail = (event: FormEvent<HTMLInputElement>) => {
    setIsInitial(false)

    if (event.currentTarget.value === "") {
      setEmailError(t("account.form.error.email.missing"))
      return
    }

    const regex = /^[a-zA-Z0-9._%+-]+@(?:(?!.*?\.\.)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
    const valid = regex.test(event.currentTarget.value)
    if (valid) setEmailError("")
    else setEmailError(t("account.form.error.email.invalid"))
  }

  const validatePassword = (event: FormEvent<HTMLInputElement>) => {
    setIsInitial(false)
    if (event.currentTarget.value === "") {
      setPasswordError(t("account.form.error.password.missing"))
      return
    }
    setPasswordError("")
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const payload = {
      username: event.currentTarget.email.value,
      password: event.currentTarget.password.value,
    }

    try {
      setIsLoading(true)

      // Call server action to be sure to 1. get an api token and 2. BEFORE login -> refers to anonymous user
      const anonymousApiToken = await getApiToken()

      const response = await fetch(
        process.env.NEXT_PUBLIC_BASE_PATH
          ? process.env.NEXT_PUBLIC_BASE_PATH + "/api/auth/islogin"
          : "/api/auth/islogin",
        {
          method: "POST",
          body: JSON.stringify(payload),
        }
      )

      if (response.status === 200) {
        // merge basket of current user AFTER login with basket cached in storefront state (i.e. anonymous basket)
        mergeCurrentBasket(basketData?.data?.id, anonymousApiToken).then((basketData) => {
          setBasketData(basketData)
        })

        setLoginFormUsername(undefined)
        setLoginFormPassword(undefined)

        window.location.href = process.env.NEXT_PUBLIC_BASE_PATH
          ? process.env.NEXT_PUBLIC_BASE_PATH + "/account"
          : "/account"
      } else {
        setPasswordError(t("account.form.error.password.invalid"))
      }
    } catch (e) {
      setPasswordError(t("account.form.error.password.invalid"))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form className={"flex flex-col gap-2"} noValidate={true} name={"LoginUserForm"} onSubmit={handleSubmit}>
      <div className="mb-3.5">
        <div className="relative">
          <input
            ref={usernameRef}
            type="email"
            name={"ShopLoginForm_Login"}
            id="email"
            autoComplete="username"
            className="block w-full rounded border border-gray-300 p-2.5 text-gray-900 outline-none focus:border-blue-800 focus:bg-red-50 focus:ring-blue-600 "
            placeholder={t("account.form.email")}
            required={true}
            onInput={validateEmail}
            onChange={(e) => setLoginFormUsername(e.target.value)}
            pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
          />
          {emailError !== undefined ? (
            <div className="absolute inset-y-0 right-5 flex items-center px-2 text-sm focus:outline-none">
              {emailError ? <XIcon className="text-red-500" /> : <Check className="text-green-500" />}
            </div>
          ) : (
            <></>
          )}
        </div>
        {emailError && <p className="text-sm text-red-500">{emailError}</p>}
      </div>

      <div className="mb-3.5">
        <div className="relative">
          <input
            ref={passwordRef}
            type={showPassword ? "text" : "password"}
            name="password"
            id="password"
            autoComplete="current-password"
            placeholder={t("account.form.password")}
            className="block w-full rounded border border-gray-300 p-2.5 text-gray-900 outline-none focus:border-blue-600 focus:bg-red-50 focus:ring-blue-600"
            required={true}
            onInput={validatePassword}
            onChange={(e) => setLoginFormPassword(e.target.value)}
          />
          <button
            type="button"
            className={cn("absolute inset-y-0 right-5 flex items-center px-2 text-gray-500 focus:outline-none", {
              "text-red-500": passwordError !== undefined && passwordError,
              "text-green-500": passwordError !== undefined && !passwordError,
            })}
            onClick={handlePasswordVisibility}
          >
            {showPassword ? <EyeIcon /> : <EyeOffIcon />}
          </button>
        </div>
        {passwordError && <p className="text-sm text-red-500">{passwordError}</p>}
      </div>

      <a href={props.forgotPasswordUrl} className="mb-7 text-gray-500 underline">
        {t("account.form.forgot_password")}
      </a>

      <Button
        disabled={isInitial || !!emailError || !!passwordError || isLoading}
        className="text-lg font-bold"
        variant={"default"}
      >
        {t("account.form.login")}
      </Button>
    </form>
  )
}
