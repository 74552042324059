import { AccountContext } from "@/lib/storefront/account/account-service"
import { Input } from "@/components/ui/components"

interface AccountSignUpEmailAndPasswordProps {
  accountContext: AccountContext
}

export default function AccountSignUpEmailAndPassword(props: AccountSignUpEmailAndPasswordProps) {
  return (
    <div>
      <div className={"flex flex-col items-start gap-2"}>
        <h2>E-Mail & Kennwort</h2>
        <p>Mit E-Mail-Adresse und Kennwort haben Sie zukünftig Zugriff auf Ihr Konto.</p>

        <p>*Erforderliches Feld</p>
      </div>

      <div className={"grid grid-cols-1 gap-2 md:grid-cols-2"}>
        <div className={"flex flex-col gap-2"}>
          <label>Email-Adresse</label>
          <Input className={""} />
        </div>

        <div>
          <label>Email-Adresse bestätigen</label>
          <Input className={""} />
        </div>

        <div>
          <label>Kennwort</label>
          <Input className={""} />
          <p>mindestens 10 Zeichen | Kleinbuchstabe | Großbuchstabe | Sonderzeichen | eine Zahl</p>
        </div>

        <div>
          <label>Kennwort Wiederholen</label>
          <Input className={""} />
        </div>
      </div>
    </div>
  )
}
