"use client"

import React from "react"
import Link from "next/link"
import { useUbStorefrontState } from "@/store/ubStorefrontState"

import { FacetData } from "@/lib/factfinder/factfinder-interfaces"
import { getCategoryLinkByName, slugifyUB } from "@/lib/link-utils/link-utils"
import { logger } from "@/lib/logger"
import { Category } from "@/lib/storefront/category/category"
import { AccordionContent, AccordionItem, AccordionRoot, AccordionTrigger } from "@/components/ui/components"
import { cn } from "@/components/ui/helpers/utils"

interface CategoryTreeProps {
  category: Category
  facet?: FacetData
  sbLocale: string
}

function TreeNode(props: { subCategory: Category; activeCategory: Category; sbLocale: string }) {
  const { setIsLoading } = useUbStorefrontState()

  function handleTreeNodeClick(e: React.MouseEvent<HTMLAnchorElement>) {
    if (!(e.ctrlKey || e.shiftKey || e.altKey || e.metaKey)) {
      // As the reset of the loading state depends on a changed URL we need to prevent it when click with modifier keys
      setIsLoading(true)
    }
  }

  return (
    <>
      {props.subCategory.subCategories.length > 0 ? (
        <>
          <AccordionItem key={props.subCategory.id} className="" value={props.subCategory.slug}>
            <AccordionTrigger className="min-h-8 bg-transparent px-1 py-2 text-start text-sm shadow-none hover:bg-gray-100">
              {props.subCategory.slug === props.activeCategory.slug ? (
                <>
                  <p
                    className={
                      "block cursor-default font-bold transition duration-200 ease-in-out hover:translate-x-1 hover:text-primary"
                    }
                  >
                    {props.subCategory.name}
                  </p>
                </>
              ) : (
                <>
                  <Link
                    onClick={handleTreeNodeClick}
                    prefetch={false}
                    className={"block pl-1 transition duration-200 ease-in-out hover:translate-x-1 hover:text-primary"}
                    href={getCategoryLinkByName(props.subCategory.urlSlug, undefined, props.sbLocale)}
                  >
                    {props.subCategory.name}
                  </Link>
                </>
              )}
            </AccordionTrigger>
            <AccordionContent className="bg-gray-100 text-sm font-normal">
              {props.subCategory?.subCategories?.map((subsubCategory: Category, index) => (
                <div key={index} className={"pl-4"}>
                  <TreeNode
                    subCategory={subsubCategory}
                    activeCategory={props.activeCategory}
                    sbLocale={props.sbLocale}
                  />
                </div>
              ))}
            </AccordionContent>
          </AccordionItem>
        </>
      ) : (
        <div key={props.subCategory.id} className={"min-h-8 py-2 text-start text-sm shadow-none"}>
          {props.subCategory.slug === props.activeCategory.slug ? (
            <>
              <p
                className={
                  "block cursor-default font-bold transition duration-200 ease-in-out  hover:translate-x-1 hover:text-primary"
                }
              >
                {props.subCategory.name}
              </p>
            </>
          ) : (
            <>
              <Link
                onClick={handleTreeNodeClick}
                prefetch={false}
                className={"block transition duration-200 ease-in-out hover:translate-x-1 hover:text-primary"}
                href={getCategoryLinkByName(props.subCategory.urlSlug, undefined, props.sbLocale)}
              >
                {props.subCategory.name}
              </Link>
            </>
          )}
        </div>
      )}
    </>
  )
}

function determineBaseCategory(props: CategoryTreeProps) {
  const category = props.category
  //console.dir(category)
  if (category) {
    if (category?.path?.length === 0) {
      return category
    } else {
      const parentCategoryName = category?.path[category?.path.length - 1] ?? ""

      const baseCategory: Category = {
        id: parentCategoryName,
        name: parentCategoryName,
        path: category?.path.slice(0, -2) ?? [],
        slug: slugifyUB(parentCategoryName),
        subCategories: [category],
        urlSlug: slugifyUB(parentCategoryName),
      }
      return baseCategory
    }
  }
}

const CategoryTree = (props: CategoryTreeProps) => {
  const categoryTreeBaseCategory = determineBaseCategory(props)
  const openCategories: string[] = []
  if (categoryTreeBaseCategory) {
    // console.log(categoryTreeBaseCategory.slug)
    openCategories.push(categoryTreeBaseCategory.slug)
    openCategories.push(props?.category.slug)
  } else {
    logger.warn(`TreeNode: Could not determine base category for category ${props.category?.name}`)
  }
  return (
    <>
      {categoryTreeBaseCategory ? (
        <div className="flex flex-col" data-id={props?.category.id}>
          <AccordionRoot className={cn("w-full")} type="multiple" defaultValue={openCategories}>
            <TreeNode
              key={categoryTreeBaseCategory.slug}
              subCategory={categoryTreeBaseCategory}
              activeCategory={props.category}
              sbLocale={props.sbLocale}
            />
            {/*   {categoryTreeBaseCategory?.subCategories?.map((subCategory: Category, index) => (
          <TreeNode key={index} subCategory={subCategory} activeCategory={props.category}/>
        ))}*/}
          </AccordionRoot>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default CategoryTree
