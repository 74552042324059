"use client"

import React from "react"
import { useUbPdpState } from "@/store/UbpdpState"
import { useTranslations } from "next-intl"

import { PdpContext } from "@/lib/storefront/product/pdp-service"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/components"
import { UseFilteredVariantsHook } from "@/components/product/pdp/UseFilteredVariantsHook"

export function ProductDetailBuyboxShowVariantsButton(props: { pdpContext: PdpContext }) {
  const t = useTranslations("storefront")
  const masterProduct = props.pdpContext.productAndArticle.masterProduct
  const filteredVariants = UseFilteredVariantsHook(masterProduct).filteredVariants
  const { useClientSelectedFilterOptions } = useUbPdpState()

  return (
    <>
      {masterProduct && filteredVariants && filteredVariants.length > 1 && (
        <Button
          className={cn("", {
            hidden: !useClientSelectedFilterOptions,
          })}
          variant={"default"}
          size={"productCard"}
          onClick={() => {
            const element = document.getElementById("variantTableAnchor")
            element?.scrollIntoView({
              behavior: "smooth",
            })
          }}
        >
          {t("product.discover_variants", { variantsLength: filteredVariants.length })}
        </Button>
      )}
    </>
  )
}
