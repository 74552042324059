"use client"

import { storyblokEditable } from "@storyblok/react/rsc"

import { ReducedProductData } from "@/lib/storefront/product/product"
import { UbProductImageStoryblok } from "@/components/storyblok/component-types"

interface UBProductImageGalleryProps {
  blok: UbProductImageStoryblok
  product: ReducedProductData
}

const UBProductImageGallery = ({ blok, ...restProps }: UBProductImageGalleryProps) => {
  const product = restProps.product

  return <div {...storyblokEditable(blok)}></div>
}

export default UBProductImageGallery
