"use client"

import React, { useState } from "react"

import { ArticleAndPrices, PdpContext } from "@/lib/storefront/product/pdp-service"
import { cn } from "@/components/ui/helpers/utils"
import ProductDetailBrandLink from "@/components/product/pdp/ProductDetailBrandLink"
import ProductDetailModalImageGallery from "@/components/product/pdp/ProductDetailModalImageGallery"

type ProductDetailShortDescriptionProps = {
  pdpContext: PdpContext
  cheapestArticleAndPrices: ArticleAndPrices | undefined
}

export default function ProductDetailImageArea({
  pdpContext,
  cheapestArticleAndPrices,
}: ProductDetailShortDescriptionProps) {
  const [imageZoomed, setImageZoomed] = useState(false)

  const handleImageZoomed = (isZoomed: boolean) => {
    setImageZoomed(isZoomed)
  }

  return (
    <div
      className={cn("top-[8.5rem] flex w-full flex-col justify-between gap-4", {
        sticky: !imageZoomed,
      })}
    >
      <ProductDetailModalImageGallery
        pdpContext={pdpContext}
        onImageZoomed={handleImageZoomed}
        cheapestArticleAndPrices={cheapestArticleAndPrices}
      />
      <ProductDetailBrandLink pdpContext={pdpContext} />
    </div>
  )
}
