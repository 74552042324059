"use client"

import React from "react"
import { useRouter } from "next/navigation"
import { useUbStorefrontState } from "@/store/ubStorefrontState"

import { Button } from "@/components/ui/components"
import { CategoryContentStoryblok } from "@/components/storyblok/component-types"

export default function GoToAdditionalCategoryButton(props: { categoryContent: CategoryContentStoryblok }) {
  const { setIsLoading } = useUbStorefrontState()
  const router = useRouter()

  function handleClick() {
    const target = props.categoryContent?.accessoriesButtonLink?.cached_url
    if (target && target !== "") {
      setIsLoading(true)
      router.push(target)
    }
  }

  return (
    <Button variant={"outline_secondary"} size={"defaultNoGrow"} onClick={handleClick}>
      <div className={"flex w-full flex-row items-center justify-between gap-1"}>
        <p>{props.categoryContent?.accessoriesButtonText ?? "Zubehör"}</p>
      </div>
    </Button>
  )
}
