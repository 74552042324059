"use client"

import React from "react"

import { AccountContext } from "@/lib/storefront/account/account-service"
import AccountSignUpAddress from "@/components/auth/signup/AccountSignUpAddress"
import AccountSignUpB2CContact from "@/components/auth/signup/AccountSignUpB2CContact"
import AccountSignUpEmailAndPassword from "@/components/auth/signup/AccountSignUpEmailAndPassword"
import AccountSignUpLegal from "@/components/auth/signup/AccountSignUpLegal"

interface AccountSignUpB2CProps {
  accountContext: AccountContext
}

export default function AccountSignUpB2C(props: AccountSignUpB2CProps) {
  return (
    <section className={"relative block bg-cover "}>
      <div className={"mx-auto w-full max-w-7xl justify-center"}>
        <div className={"flex w-full flex-col items-start justify-start gap-2 pt-4"}>
          <div className={"w-full grow bg-ub-background"}>Privatkunde</div>
          <AccountSignUpEmailAndPassword accountContext={props.accountContext} />
          <AccountSignUpB2CContact accountContext={props.accountContext} />
          <AccountSignUpAddress accountContext={props.accountContext} />
          <AccountSignUpLegal accountContext={props.accountContext} />
        </div>
      </div>
    </section>
  )
}
