"use client"

import React, { HTMLAttributes, useCallback, useState } from "react"
import Link, { type LinkProps } from "next/link"
import {
  Building,
  ChevronDownIcon,
  ChevronUpIcon,
  Home,
  LogOut,
  Mails,
  MapPinned,
  NotebookPen,
  NotebookPenIcon,
  Package,
  UserRoundCog,
  Users,
  type LucideIcon,
} from "lucide-react"
import { useTranslations } from "next-intl"

import { cn } from "@/lib/utils"

const accountNavigationLinks: AccountNavigationLinkProps[] = [
  {
    href: "/account/dashboard",
    text: "account.nav.dashboard",
    id: "dashboard",
    icon: Home,
  },
  {
    href: "/account/orders",
    text: "account.nav.orders",
    id: "orders",
    icon: Package,
  },
  {
    href: "/account/wishlists",
    text: "account.nav.wishlists",
    id: "wishlists",
    icon: NotebookPen,
  },
  {
    href: "/account/addresses",
    text: "account.nav.addresses",
    id: "addresses",
    icon: MapPinned,
  },
  {
    href: "/account/profile",
    text: "account.nav.profile",
    id: "profile",
    icon: Building,
  },
  {
    href: "/account/articles",
    text: "account.nav.articles",
    id: "articles",
    icon: UserRoundCog,
  },
  {
    href: "/account/usermanagement",
    text: "account.nav.usermanagement",
    id: "usermanagement",
    icon: Users,
  },
  {
    href: "/account/quotes",
    text: "account.nav.quotes",
    id: "quotes",
    icon: NotebookPenIcon,
  },
  {
    href: "/account/subscriptions",
    text: "account.nav.subscriptions",
    id: "subscriptions",
    icon: Mails,
  },
]

interface AccountNavigationLinkProps extends LinkProps, HTMLAttributes<HTMLAnchorElement> {
  text: string
  icon?: LucideIcon
  active?: boolean
}

function AccountNavigationLink(props: AccountNavigationLinkProps) {
  const t = useTranslations("storefront")

  return (
    <Link
      prefetch={false}
      href={props.href}
      className={cn(
        "flex w-full items-center bg-card py-2.5 pl-3 pr-2.5 leading-7 hover:text-primary",
        {
          "bg-primary text-primary-foreground hover:text-primary-foreground hover:bg-primary/90": props.active,
        },
        props.className
      )}
    >
      {props.icon && <props.icon className={"mr-4 inline-block"} size={20} />} {t(props.text)}
    </Link>
  )
}

interface AccountNavigationProps {
  activeMenuItem: string
}

export default function AccountNavigation(props: AccountNavigationProps) {
  const [open, setOpen] = useState<boolean>(false)
  const toggle = useCallback((): void => setOpen((state) => !state), [])
  const t = useTranslations("storefront")

  return (
    <>
      <button className={"flex w-full items-center justify-center bg-white p-2.5 text-xl md:hidden"} onClick={toggle}>
        {t("account.nav.menu")}
        {open ? (
          <ChevronUpIcon className="ml-2 h-[16px] text-primary" />
        ) : (
          <ChevronDownIcon className="ml-2 h-[16px] text-primary" />
        )}
      </button>
      <div className={"md:max-max-h-none relative max-h-full w-full min-w-72 max-w-80"}>
        <div
          className={cn(
            "absolute inset-x-0 z-50 max-h-full min-h-[calc(100vh-1rem)] w-full overflow-y-auto overflow-x-hidden bg-black/30 md:relative md:inset-0 md:inset-x-auto md:z-auto md:block md:max-h-none md:min-h-fit md:w-auto  md:rounded-sm md:border md:border-gray-200 md:bg-white ",
            {
              hidden: !open,
            }
          )}
          onClick={toggle}
        >
          <nav className={cn("m-auto w-[calc(100%-100px)] text-base font-bold md:block md:w-auto")}>
            {accountNavigationLinks.map((navigationLink) => (
              <AccountNavigationLink
                key={navigationLink.id}
                {...navigationLink}
                active={navigationLink.id === props.activeMenuItem}
              />
            ))}
            <Link
              prefetch={false}
              href="#"
              className={
                "flex w-full items-center bg-card py-2.5 pl-3 pr-2.5 font-normal leading-7 hover:font-bold hover:text-primary"
              }
            >
              <LogOut className={"mr-4 inline-block text-primary"} /> {t("account.nav.sign-out")}
            </Link>
          </nav>
        </div>
      </div>
    </>
  )
}
