"use client"

import { useState } from "react"
import { useSearchParams } from "next/navigation"
import { SEARCH_PARAMS_MAX_PRICE, SEARCH_PARAMS_MIN_PRICE } from "@/constants/ui"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { useTranslations } from "next-intl"

import { getCurrency } from "@/lib/locale/locale-common-utils"
import { Button } from "@/components/ui/components"

import FormWithSearchParams from "../ui/helpers/FormWithSearchParams"
import Facet, { FacetProps } from "./Facet"

export function FacetForPrice(props: FacetProps) {
  const t = useTranslations("storefront")
  const { setIsLoading } = useUbStorefrontState()

  const searchParams = useSearchParams()

  const showSearchFields = true

  const [inputMinValue, setInputMinValue] = useState(searchParams?.get(SEARCH_PARAMS_MIN_PRICE) || "")
  const [inputMaxValue, setInputMaxValue] = useState(searchParams?.get(SEARCH_PARAMS_MAX_PRICE) || "")

  const handleMinChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, "")
    setInputMinValue(numericValue)
  }

  const handleMaxChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, "")
    setInputMaxValue(numericValue)
  }

  return (
    <div>
      <Facet
        isoLocale={props.isoLocale}
        query={props.query}
        baseCategory={props.baseCategory}
        brandSlug={props.brandSlug}
        facetData={props.facetData}
      />

      {showSearchFields && (
        <FormWithSearchParams
          method="GET"
          noValidate={true}
          name={"PriceFilterForm"}
          ownParams={[SEARCH_PARAMS_MIN_PRICE, SEARCH_PARAMS_MAX_PRICE]}
          className="mt-4 flex flex-row items-center gap-1"
        >
          <div className="flex w-full flex-row items-center rounded-lg border border-gray-300 px-1 py-2 text-xs">
            <span className="px-1 text-black">{getCurrency(props.isoLocale)}</span>
            <input
              onChange={handleMinChange}
              name={SEARCH_PARAMS_MIN_PRICE}
              type="text"
              // type="number" // also adds up/down buttons :-(
              value={inputMinValue}
              placeholder={t("common.min_price")}
              className="w-full border-transparent p-0 text-xs text-black ring-0 focus:border-transparent focus:outline-none focus:ring-0"
              inputMode="numeric"
              maxLength={5}
              pattern="[0-9]{0,5}"
            />
          </div>

          <div className="flex w-full flex-row items-center rounded-lg border border-gray-300 px-1 py-2 text-xs">
            <span className="px-1 text-black">{getCurrency(props.isoLocale)}</span>
            <input
              onChange={handleMaxChange}
              name={SEARCH_PARAMS_MAX_PRICE}
              type="text"
              value={inputMaxValue}
              placeholder={t("common.max_price")}
              className="w-full border-transparent p-0 text-xs text-black ring-0 focus:border-transparent focus:outline-none focus:ring-0"
              inputMode="numeric"
              maxLength={5}
              pattern="[0-9]{0,5}"
            />
          </div>

          <Button
            type="submit"
            size={"sm"}
            variant={inputMinValue !== "" || inputMaxValue !== "" ? "default" : "secondary"}
            className="3xl:text-sm"
            onClick={() => setIsLoading(true)}
          >
            {t("common.go")}
          </Button>
        </FormWithSearchParams>
      )}
    </div>
  )
}
