"use client"

import { useForm } from "react-hook-form"

import { AccountContext } from "@/lib/storefront/account/account-service"
import { Button, Input, Label } from "@/components/ui/components"

interface AccountProfileEmailProps {
  accountContext: AccountContext
}

interface Inputs {
  password: string
  newEmail: string
  newEmailRepeat: string
}

export default function AccountProfileEmail(props: AccountProfileEmailProps) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>()

  let onSubmit = function (data: Inputs) {
    console.log(data)
  }

  return (
    <>
      <div className={"flex flex-col gap-4"}>
        <div className={"flex flex-col gap-2"}>
          <h2 className={"text-lg font-bold"}>E-Mail bearbeiten</h2>
          <p>E-Mail ändern</p>
          <p className={"text-xs"}>*Erforderlich</p>
        </div>

        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={"flex flex-col gap-8"}>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-6 md:col-span-2">
                <div className="sm:col-span-6">
                  <Label htmlFor="newEmail">Neue E-Mail Adresse*</Label>
                  <div className="mt-2">
                    <Input type="email" {...register("newEmail", { required: true })} autoComplete="email" />
                    <p className={"text-xs text-red-500"}>{errors.newEmail && <span>Email is required</span>}</p>
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <Label htmlFor="newEmailRepeat">Neue E-Mail Adresse (Wiederholung)*</Label>
                  <div className="mt-2">
                    <Input type="email" {...register("newEmailRepeat", { required: true })} autoComplete="email" />

                    <p className={"text-xs text-red-500"}>
                      {errors.newEmailRepeat && <span>Email repeat is required</span>}
                    </p>
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <Label htmlFor="password">Passwort*</Label>
                  <div className="mt-2">
                    <Input type="password" autoComplete="password" {...register("password", { required: true })} />

                    <p className={"text-xs text-red-500"}>{errors.password && <span>Password is required</span>}</p>
                  </div>
                </div>
              </div>
              <div className="flex w-full max-w-2xl justify-end sm:col-span-6">
                <Button size={"defaultNoGrow"} type="submit">
                  Speichern
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
