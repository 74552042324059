"use client"

import React, { useCallback, useEffect, useState } from "react"
import Cookies from "js-cookie"
import { XIcon } from "lucide-react"

import { ExitPopupCookieValue, UB_EXIT_POPUP_COOKIE_NAME } from "@/lib/exit-popup-cookies"

export default function ExitDialogClient(props) {
  const [ecShown, setEcShown] = useState<boolean>(true)
  const [leaveDetected, setLeaveDetected] = useState<boolean>(false)

  const hideExitPopup = () => {
    setEcShown(true)
    Cookies.set(UB_EXIT_POPUP_COOKIE_NAME, "true", { expires: 7 })
  }

  const handleLeaveDetected = useCallback(
    (e: MouseEvent) => {
      if (!leaveDetected) {
        if (e.clientY < 10) {
          setLeaveDetected(true)
        }
      }
    },
    [leaveDetected]
  )

  useEffect(() => {
    const exitPopupCookieValue = Cookies.get(UB_EXIT_POPUP_COOKIE_NAME)
    const ecShownFromCookie = ExitPopupCookieValue.isEcShown(exitPopupCookieValue)
    setEcShown(ecShownFromCookie)
  }, [])

  useEffect(() => {
    if (!ecShown) {
      setTimeout(() => {
        document.addEventListener("mouseout", handleLeaveDetected)
      }, 1000)
    }
    return () => {
      document.removeEventListener("mouseout", handleLeaveDetected)
    }
  }, [ecShown, handleLeaveDetected, leaveDetected])

  return (
    <>
      {!ecShown && leaveDetected && (
        <div className={"fixed inset-0 z-50 bg-black/90"} onClick={hideExitPopup}>
          <div className={"absolute left-1/2 top-1/3 -translate-x-1/2 -translate-y-1/2 pt-4"}>
            <XIcon
              onClick={hideExitPopup}
              className="absolute right-0 top-0 size-5 cursor-pointer bg-white fill-black"
            ></XIcon>

            <div className={"bg-white p-2"}>{props.children}</div>
          </div>
        </div>
      )}
    </>
  )
}
