import { SearchParams } from "@ub/fact-finder-client"

import { FacetData, FacetElementData } from "@/lib/factfinder/factfinder-interfaces"
import { CustomFilterStoryblok } from "@/components/storyblok/component-types"

export function buildCustomFilterAttributesMap(
  customFilterEnabled: boolean | undefined,
  customFilterList: CustomFilterStoryblok[] | undefined
): Record<string, CustomFilterStoryblok> {
  if (customFilterEnabled && customFilterList && customFilterList.length > 0) {
    const customFilterAttributes: Record<string, CustomFilterStoryblok> = {}

    customFilterList.map((customFilter: CustomFilterStoryblok) => {
      if (customFilter?.attribute && customFilter?.attribute !== "") {
        customFilterAttributes[customFilter.attribute.toLowerCase()] = customFilter
      }
    })
    //console.dir(customFilterAttributes, { depth: 5 })
    return customFilterAttributes
  }
  return {}
}

export function buildAllFacetsFilteredMap(
  allFacets: FacetData[],
  customFilterList: CustomFilterStoryblok[] | undefined
): Record<string, FacetData> {
  if (allFacets && customFilterList && customFilterList.length > 0) {
    const allFacetsFilteredMap: Record<string, FacetData> = {}

    customFilterList.map((customFilter: CustomFilterStoryblok) => {
      const foundFacet = allFacets.find((facetData) => {
        if (facetData.name.toLowerCase() === customFilter?.attribute?.toLowerCase()) {
          return facetData
        }
      })
      if (foundFacet) {
        allFacetsFilteredMap[foundFacet.name.toLowerCase()] = foundFacet
      }
    })

    return allFacetsFilteredMap
  }

  return {}
}

export function hasFFSearchParam(facetData: FacetData, ffSearchParams: SearchParams | undefined) {
  if (ffSearchParams?.filters) {
    const ffFilterValue = ffSearchParams?.filters.find((value) => {
      if (value.name === facetData.fieldName) {
        return value
      }
    })
    if (ffFilterValue) {
      return true
    }
  }
  return false
}
