"use client"

import * as React from "react"
import { useMemo, useState } from "react"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import { useTranslations } from "next-intl"

import { FacetData, FacetElementData } from "@/lib/factfinder/factfinder-interfaces"
import { Category } from "@/lib/storefront/category/category"
import { cn } from "@/lib/utils"
import { sortByAlphanumeric, sortByMinShipmentDays } from "@/components/search-and-category/customfilter/sortHelper"

import FacetElement from "./FacetElement"

export interface FacetProps {
  facetData: FacetData
  query?: string
  baseCategory?: Category
  brandSlug?: string
  filterFunction?: (facetElement: FacetElementData) => boolean
  isoLocale: string
}

const UNSELECTED_ELEMENT_COUNT_TO_SHOW = 10

export default function Facet(props: FacetProps) {
  const t = useTranslations("storefront")

  const [showAll, setShowAll] = useState(false)
  let showUnselectedOnlyFirst: number = UNSELECTED_ELEMENT_COUNT_TO_SHOW
  if (props.filterFunction) {
    // Show all elements if user used a filter
    showUnselectedOnlyFirst = Infinity
  }
  const hiddenCount = (props.facetData?.facetElements?.length || 0) - showUnselectedOnlyFirst
  if (hiddenCount <= 1) {
    showUnselectedOnlyFirst = Infinity
  }

  const isShipmentDurationFacet = useMemo(() => {
    return props.facetData?.fieldName === "readyForShipmentMin"
  }, [props.facetData?.fieldName])

  const sortFunction = useMemo(() => {
    return isShipmentDurationFacet ? sortByMinShipmentDays : sortByAlphanumeric
  }, [isShipmentDurationFacet])

  const sortedAndFilteredUnselectedFacetElements = useMemo(() => {
    if (props.facetData?.facetElements) {
      return props.facetData.facetElements
        .filter((elemData: FacetElementData) => !props.filterFunction || props.filterFunction(elemData))
        .sort(sortFunction)
    } else {
      return []
    }
  }, [props, sortFunction])
  return (
    <div className="mt-3 flex flex-col gap-2">
      {/* selected elements are always shown */}
      <div className="flex flex-col gap-2">
        {props.facetData?.selectedFacetElements &&
          props.facetData.selectedFacetElements
            .filter((elemData: FacetElementData) => !props.filterFunction || props.filterFunction(elemData))
            .map((elemData: FacetElementData, index) => (
              <FacetElement
                isoLocale={props.isoLocale}
                key={elemData.elementname}
                query={props.query}
                baseCategory={props.baseCategory}
                brandSlug={props.brandSlug}
                facetElementData={elemData}
                unit={props.facetData.unit}
              />
            ))}
      </div>

      {/* unselected elements: only show first showUnselectedOnlyFirst */}
      <div className="flex flex-col gap-2">
        {sortedAndFilteredUnselectedFacetElements.map((elemData: FacetElementData, index) => (
          <div key={elemData.elementname} className={index < showUnselectedOnlyFirst || showAll ? "block" : "hidden"}>
            <FacetElement
              isoLocale={props.isoLocale}
              key={elemData.elementname}
              query={props.query}
              baseCategory={props.baseCategory}
              brandSlug={props.brandSlug}
              facetElementData={elemData}
              unit={props.facetData.unit}
            />
          </div>
        ))}
        {props.facetData?.facetElements && props.facetData.facetElements.length > showUnselectedOnlyFirst && (
          <button
            className="my-2 flex w-full justify-between rounded-sm border border-gray-200 bg-white p-2 text-xs font-bold text-gray-500"
            onClick={() => setShowAll(!showAll)}
          >
            <p>{showAll ? t("common.show_less") : t("common.show_further_count", { count: hiddenCount })}</p>
            <ChevronDownIcon
              className={cn("", {
                "rotate-180": showAll,
              })}
              aria-hidden
            />
          </button>
        )}
      </div>
    </div>
  )
}
