"use client"

import React, { useEffect, useState } from "react"
import { useRouter } from "next/navigation"
import {
  addOpenTenderPaymentAction,
  addPayoneCreditCardPaymentAction,
  loadBasketPaymentMethodsAction,
  removePaymentInstrumentAction,
} from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { BasketCtnrROV1IncludedPaymentsPaymentInstrument, PaymentMethodROV1 } from "@ub/basket-client"
import { useTranslations } from "next-intl"

import { CheckoutContext } from "@/lib/storefront/checkout/checkout-service"
import { AccordionContent, AccordionItem, AccordionRoot, AccordionTrigger, Button } from "@/components/ui/components"
import PayoneCreditCardHostedIFrames from "@/components/checkout/payment/payone/PayoneCreditCardHostedIFrames"
import BasicCard from "@/components/common/BasicCard"

const PAYONE_CREDITCARD_SERVICE_ID = "Payone_CreditCard"

interface CheckoutPaymentProps {
  checkoutContext: CheckoutContext
}

export default function CheckoutPayment(props: CheckoutPaymentProps) {
  const t = useTranslations("storefront")

  const router = useRouter()
  const { basketData } = useUbStorefrontState()
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodROV1[]>([])
  const [paymentInstruments, setPaymentInstruments] = useState<BasketCtnrROV1IncludedPaymentsPaymentInstrument>({})
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const isB2B = props.checkoutContext.isB2B

  const paymentMethodIds = paymentMethods.map((pm) => {
    return pm.id ?? ""
  })

  useEffect(() => {
    loadBasketPaymentMethodsAction(isB2B).then(async (paymentMethods) => {
      if (paymentMethods) {
        setPaymentMethods(paymentMethods.data ?? [])
        setPaymentInstruments(paymentMethods.included?.paymentInstruments ?? {})
      }
    })
  }, [basketData, isB2B])

  function removePaymentInstrument(paymentInstrument: string | undefined) {
    if (paymentInstrument) {
      removePaymentInstrumentAction(paymentInstrument, isB2B).then((value) => {
        if (value.result === "NOK") {
          setErrorMessage(value.message)
        } else {
          const copyOfPaymentInstruments = { ...paymentInstruments }
          delete copyOfPaymentInstruments[paymentInstrument]
          setPaymentInstruments((paymentInstrument) => ({ ...copyOfPaymentInstruments }))
        }
      })
    }
  }

  function addPayment(paymentInstrument: string | undefined) {
    if (paymentInstrument) {
      addOpenTenderPaymentAction(paymentInstrument, isB2B).then((value) => {
        if (value.result === "NOK") {
          setErrorMessage(value.message)
        } else {
          router.push("/basket/checkout/review")
        }
      })
    }
  }

  function addPaymentAction(formData: FormData) {
    const paymentMethodID = formData.get("paymentMethodID") as unknown as string
    const paymentMethodServiceID = paymentMethods?.find(
      (paymentMethod) => paymentMethod.id === paymentMethodID
    )?.serviceID
    if (paymentMethodServiceID === PAYONE_CREDITCARD_SERVICE_ID) {
      const pseudocardpan = formData.get("pseudocardpan") as object
      const truncatedcardpan = formData.get("truncatedcardpan") as object
      if (pseudocardpan && truncatedcardpan) {
        const parameters = {
          pseudocardpan: pseudocardpan,
          truncatedcardpan: truncatedcardpan,
        }
        addPayoneCreditCardPaymentAction(paymentMethodID, parameters, isB2B).then((value) => {
          if (value.result === "NOK") {
            setErrorMessage(value.message)
          } else {
            router.push("/basket/checkout/review")
          }
        })
      }
    }
  }

  return (
    <div className={"flex flex-row w-full"}>
      <div className={"flex flex-col gap-2 w-full"}>
        <h2 className={"text-lg font-bold"}>{t("checkout.payment.cta")}</h2>
        {errorMessage && <p className={"text-xs text-red-500"}>{errorMessage}</p>}

        <form name={"PaymentForm"} action={addPaymentAction}>
          <AccordionRoot type={"multiple"} className={"w-full"} defaultValue={paymentMethodIds}>
            {paymentMethods.map((paymentMethod, index) => (
              <AccordionItem key={paymentMethod.id} value={paymentMethod.id ?? ""}>
                <AccordionTrigger>{paymentMethod.displayName}</AccordionTrigger>
                <AccordionContent>
                  <BasicCard>
                    {paymentMethod.serviceID === PAYONE_CREDITCARD_SERVICE_ID ? (
                      <>
                        <PayoneCreditCardHostedIFrames paymentMethod={paymentMethod} />
                        {paymentMethod.paymentInstruments
                          ?.filter((paymentInstrument) => paymentInstruments[paymentInstrument])
                          .map((paymentInstrument, index) => (
                            <div key={"instrument-" + index}>
                              <hr className={"m-4"} />
                              <div className={"flex flex-col gap-2"}>
                                <h2 className={"text-large font-bold"}>
                                  {t("checkout.payment.payone.creditcard.cardnumber")}
                                </h2>
                                <div className={"flex flex-row items-center gap-2"}>
                                  <div>{paymentInstruments[paymentInstrument].accountIdentifier}</div>
                                  <Button
                                    size={"defaultNoGrow"}
                                    variant={"default"}
                                    className={"cursor-pointer bg-primary py-0 font-normal text-white"}
                                    type={"button"}
                                    onClick={() => removePaymentInstrument(paymentInstrument)}
                                  >
                                    {t("checkout.payment.payone.creditcard.remove")}
                                  </Button>
                                </div>
                                <div className={"flex justify-end"}>
                                  <Button
                                    size={"defaultNoGrow"}
                                    variant={"default"}
                                    className={"cursor-pointer bg-primary py-0 font-normal text-white"}
                                    type={"button"}
                                    onClick={() => addPayment(paymentInstrument)}
                                  >
                                    {t("checkout.payment.button")}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ))}
                      </>
                    ) : (
                      <>
                        <div>{paymentMethod.description}</div>
                        <div className={"flex justify-end"}>
                          <Button
                            size={"defaultNoGrow"}
                            variant={"default"}
                            className={"cursor-pointer bg-primary py-0 font-normal text-white"}
                            type={"button"}
                            onClick={() => addPayment(paymentMethod.paymentInstruments?.[0])}
                          >
                            {t("checkout.payment.button")}
                          </Button>
                        </div>
                      </>
                    )}
                  </BasicCard>
                </AccordionContent>
              </AccordionItem>
            ))}
          </AccordionRoot>
        </form>
      </div>
    </div>
  )
}
