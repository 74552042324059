"use client"

import { useEffect, useState } from "react"
import { sendGTMEvent } from "@next/third-parties/google"

import { CategoryBrandsProps } from "@/components/search-and-category/CategoryBrandsSection"

const CategoryBrandsSectionTrackingClient = (props: CategoryBrandsProps) => {
  const [tracked, setTracked] = useState(false)

  useEffect(() => {
    if (!tracked) {
      //console.log("Send Brand Tracking")

      const brandPromotionData = {
        event: "promotion_impressions",
        ecommerce: {
          currencyCode: "EUR",
          promoView: {
            promotions: [] as any[],
          },
        },
      }

      for (let i = 0; i < props.categoryBrands.length; i++) {
        const brand = props.categoryBrands[i]

        // @ts-ignore
        brandPromotionData.ecommerce.promoView.promotions.push({
          creative: brand.img,
          name: brand.name,
          position: "Category Brand Teaser " + i + 1,
        })
      }

      sendGTMEvent(brandPromotionData)
      setTracked(true)
    }
  }, [])

  return <span className={"hidden"}>CategoryBrandsSectionTrackingClient</span>
}
export default CategoryBrandsSectionTrackingClient

/*
{
  "event": "promotion_impressions",
  "ecommerce": {
  "currencyCode": "EUR",
    "promoView": {
    "promotions": [
      {
        "creative": "kappes1.png",
        "name": "Kappes Standard 1 M",
        "position": "Category Brand Teaser 1"
      },
      {
        "creative": "lista1.png",
        "name": "LISTA Standard 1 M",
        "position": "Category Brand Teaser 2"
      },
      {
        "creative": "meta1.png",
        "name": "META Standard 1 M",
        "position": "Category Brand Teaser 3"
      },
      {
        "creative": "pavoy1.png",
        "name": "PAVOY Standard 1 M",
        "position": "Category Brand Teaser 4"
      },
      {
        "creative": "Text Link",
        "name": "\nAlle anzeigen",
        "position": "Category Brand Teaser all"
      }
    ]
  }
},
  "gtm.uniqueEventId": 193
}*/
