import { getCountry, getLanguageFromIsoLocale } from "@/lib/locale/locale-common-utils"

interface StoryblokSwissLocaleSwitchProps {
  isoLocale: string
}

export default function StoryblokSwissLocaleSwitch({ isoLocale }: StoryblokSwissLocaleSwitchProps) {
  let switchToLocale: string | undefined = undefined

  if (getCountry(isoLocale) === "ch") {
    switchToLocale = getLanguageFromIsoLocale(isoLocale) === "de" ? "fr-ch" : "de-ch"
  }

  return (
    <>
      {switchToLocale && (
        <div className={"ml-8 flex justify-end"}>
          <button
            className={"flex items-center justify-between gap-1"}
            onClick={() => {
              location.href = document.querySelector(`link[hreflang=${switchToLocale}]`)?.getAttribute("href") ?? "/"
            }}
          >
            <div className="relative aspect-square h-5 rounded-sm bg-red-600">
              <span className="absolute inset-x-[40%] inset-y-[10%] bg-white"></span>
              <span className="absolute inset-x-[10%] inset-y-[40%] bg-white"></span>
            </div>
            <span>{getLanguageFromIsoLocale(switchToLocale).toUpperCase()}</span>
          </button>
        </div>
      )}
    </>
  )
}
