"use client"

import * as React from "react"
import { useMemo } from "react"
import { useRouter } from "next/navigation"
import { useUbStorefrontState } from "@/store/ubStorefrontState"

import { FacetData, FacetElementData } from "@/lib/factfinder/factfinder-interfaces"
import { Category } from "@/lib/storefront/category/category"
import {
  noSort,
  sortByAlphanumeric,
  sortByHitCount,
  sortByNumeric,
} from "@/components/search-and-category/customfilter/sortHelper"
import { AssetStoryblok, CustomFilterStoryblok } from "@/components/storyblok/component-types"

import CustomBadgeFacetElement from "./CustomBadgeFacetElement"

export interface CustomBadgeFacetProps {
  facetData: FacetData | undefined
  query?: string
  baseCategory?: Category
  brandSlug?: string
  filterFunction?: (facetElement: FacetElementData) => boolean
  customFilter?: CustomFilterStoryblok
  isoLocale: string
}

export default function CustomBadgeFacet(props: CustomBadgeFacetProps) {
  const { setIsLoading } = useUbStorefrontState()
  const router = useRouter()

  const allFacetElements = useMemo(() => {
    const combinedElements = props.facetData?.facetElements.concat(props.facetData.selectedFacetElements)

    return combinedElements
  }, [props.facetData])

  function determineSort(customFilter: CustomFilterStoryblok | undefined) {
    if (customFilter?.sortOrder === "HITS") {
      return sortByHitCount
    } else if (customFilter?.sortOrder === "ALPHANUMERIC") {
      return sortByAlphanumeric
    } else if (customFilter?.sortOrder === "NUMERIC") {
      return sortByNumeric
    }

    return noSort
  }

  function handleSelect(allElemData: FacetElementData[] | undefined, currentElemData: FacetElementData, link: string) {
    if (allElemData) {
      allElemData.forEach((item) => {
        item.selected = false
      })
    }
    currentElemData.selected = true
    setIsLoading(true)
    router.push(link)
  }

  function determineActiveValueIcon(
    facetElementData: FacetElementData,
    customFilter: CustomFilterStoryblok | undefined
  ): AssetStoryblok | undefined {
    const elementname = facetElementData.elementname
    const filterValueIcons = customFilter?.filterValueIcons
    let result: AssetStoryblok | undefined = undefined

    if (filterValueIcons && elementname) {
      filterValueIcons.forEach((value) => {
        if (value.filterValue === elementname && value.active === true && value?.icon !== undefined) {
          result = value.icon
        }
      })
    }
    return result
  }

  return (
    <div className="flex flex-row flex-wrap gap-2">
      {allFacetElements &&
        allFacetElements
          .sort(determineSort(props.customFilter))
          .map((facetElementData: FacetElementData, index) => (
            <CustomBadgeFacetElement
              isoLocale={props.isoLocale}
              key={facetElementData.elementname}
              allFacetElements={allFacetElements}
              query={props.query}
              baseCategory={props.baseCategory}
              brandSlug={props.brandSlug}
              facetElementData={facetElementData}
              unit={props.facetData?.unit ?? ""}
              icon={determineActiveValueIcon(facetElementData, props.customFilter)}
              handleSelect={handleSelect}
            />
          ))}
    </div>
  )
}
