"use client"

import * as React from "react"
import { useMemo } from "react"

import { FacetData, FacetElementData } from "@/lib/factfinder/factfinder-interfaces"
import { Category } from "@/lib/storefront/category/category"
import {
  noSort,
  sortByAlphanumeric,
  sortByHitCount,
  sortByNumeric,
  sortBySelectedAlphanumeric,
  sortBySelectedNumeric,
} from "@/components/search-and-category/customfilter/sortHelper"
import { CustomFilterStoryblok } from "@/components/storyblok/component-types"

import CustomDropdownFacetElement from "./CustomDropdownFacetElement"

export interface CustomDropdownFacetProps {
  isoLocale: string
  facetData: FacetData | undefined
  query?: string
  baseCategory?: Category
  brandSlug?: string
  filterFunction?: (facetElement: FacetElementData) => boolean
  customFilter?: CustomFilterStoryblok
  handleSelect: (allElemData: FacetElementData[], currentElemData: FacetElementData, link: string) => void
}

export default function CustomDropdownFacet(props: CustomDropdownFacetProps) {
  const allFacetElements = useMemo(() => {
    const combinedElements = props.facetData?.facetElements.concat(props.facetData.selectedFacetElements)

    return combinedElements
  }, [props.facetData])

  function determineSort(customFilter: CustomFilterStoryblok | undefined) {
    if (customFilter?.sortOrder === "HITS") {
      return sortByHitCount
    } else if (customFilter?.sortOrder === "ALPHANUMERIC") {
      return sortByAlphanumeric
    } else if (customFilter?.sortOrder === "NUMERIC") {
      console.log("Num : ")
      return sortByNumeric
    } else if (customFilter?.sortOrder === "SELECTED_NUMERIC") {
      console.log("Num selected: ")
      return sortBySelectedNumeric
    } else if (customFilter?.sortOrder === "SELECTED_ALPHANUMERIC") {
      return sortBySelectedAlphanumeric
    }

    return noSort
  }

  return (
    <div className="mt-3 flex flex-col" key={props?.facetData?.fieldName ?? "" + "_ddf"}>
      {/* selected elements are always shown */}
      <div className="flex flex-col gap-2">
        {props.facetData?.selectedFacetElements &&
          props.facetData.selectedFacetElements
            .filter((elemData: FacetElementData) => !props.filterFunction || props.filterFunction(elemData))
            .map((elemData: FacetElementData, index) => (
              <CustomDropdownFacetElement
                key={elemData.elementname + (props?.facetData?.fieldName ?? "")}
                isoLocale={props.isoLocale}
                allFacetElements={allFacetElements}
                fieldName={props?.facetData?.fieldName ?? ""}
                query={props.query}
                baseCategory={props.baseCategory}
                brandSlug={props.brandSlug}
                facetElementData={elemData}
                unit={props.facetData?.unit ?? ""}
                handleSelect={props.handleSelect}
              />
            ))}
      </div>

      {/* unselected elements: only show first showUnselectedOnlyFirst */}
      <div className="flex flex-col gap-2">
        {props.facetData?.facetElements &&
          props.facetData.facetElements
            .filter((elemData: FacetElementData) => !props.filterFunction || props.filterFunction(elemData))
            .sort(determineSort(props.customFilter))
            .map((elemData: FacetElementData, index) => (
              <CustomDropdownFacetElement
                key={elemData.elementname + (props?.facetData?.fieldName ?? "")}
                isoLocale={props.isoLocale}
                allFacetElements={allFacetElements}
                fieldName={props?.facetData?.fieldName ?? ""}
                query={props.query}
                baseCategory={props.baseCategory}
                brandSlug={props.brandSlug}
                facetElementData={elemData}
                unit={props.facetData?.unit ?? ""}
                handleSelect={props.handleSelect}
              />
            ))}
      </div>
    </div>
  )
}
