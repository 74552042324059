"use client"

import { useState } from "react"
import { Search, X } from "lucide-react"
import { useTranslations } from "next-intl"

import { FacetElementData } from "@/lib/factfinder/factfinder-interfaces"

import Facet, { FacetProps } from "./Facet"

export function FacetForBrand(props: FacetProps) {
  const t = useTranslations("storefront")

  const [filterString, setFilterString] = useState("")

  const handleInputChange = (event) => {
    setFilterString(event.target.value)
  }

  const clearInput = () => {
    setFilterString("")
  }

  const showSearchField = props.facetData.facetElements.length >= 3

  function isFacetVisible(facetElement: FacetElementData) {
    const visible = !filterString || facetElement.elementname.toLowerCase().includes(filterString?.toLocaleLowerCase())
    return visible
  }

  return (
    <div>
      {showSearchField && (
        <div className="relative my-4 h-9 rounded-lg border border-gray-300 px-1 py-0">
          <input
            className="absolute inset-y-1 left-4 right-8 m-0 border-transparent p-0 text-sm text-black ring-0 focus:border-transparent focus:outline-none focus:ring-0"
            type="text"
            onChange={handleInputChange}
            placeholder={t("common.search")}
            value={filterString}
          />
          {filterString ? (
            <X
              className="absolute right-3 top-1/2 size-4 -translate-y-1/2 cursor-pointer text-gray-500"
              onClick={clearInput}
            />
          ) : (
            <Search className="absolute right-3 top-1/2 size-4 -translate-y-1/2 text-gray-500" />
          )}
        </div>
      )}

      <Facet
        isoLocale={props.isoLocale}
        query={props.query}
        baseCategory={props.baseCategory}
        facetData={props.facetData}
        brandSlug={props.brandSlug}
        filterFunction={filterString ? isFacetVisible : undefined}
      />
    </div>
  )
}
