import React, { PropsWithChildren } from "react"

import { cn } from "@/lib/utils"

interface AccountCardProps extends PropsWithChildren {
  className?: string
}

export default function BasicCard(props: AccountCardProps) {
  return (
    <div className={cn("min-h-64 rounded-sm border border-gray-200 bg-white p-2 sm:p-4", props.className)}>
      {props.children}
    </div>
  )
}
