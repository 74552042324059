import { useCallback, useMemo, useState } from "react"
import { useRouter } from "next/navigation"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { SearchParams } from "@ub/fact-finder-client"
import { ChevronDown, Info, XCircleIcon } from "lucide-react"
import { useTranslations } from "next-intl"

import { FacetData, FacetElementData } from "@/lib/factfinder/factfinder-interfaces"
import { getResetSingleAttributeLinkToSearchResultOrCategory } from "@/lib/link-utils/link-utils"
import { Category } from "@/lib/storefront/category/category"
import { cn } from "@/lib/utils"
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/components"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/components/tooltip"
import CustomDropdownFacet from "@/components/search-and-category/customfilter/dropdown/CustomDropdownFacet"
import { hasFFSearchParam } from "@/components/search-and-category/customfilter/filterHelper"
import StoryblokAssetClient from "@/components/storyblok/common/StoryblokAssetClient"
import { CustomFilterStoryblok } from "@/components/storyblok/component-types"

export function CustomDropdownFilterWidget(props: {
  isoLocale: string
  customFilter?: CustomFilterStoryblok
  query: string | undefined
  baseCategory: Category | undefined
  facetData: FacetData | undefined
  ffSearchParams?: SearchParams | undefined
  overlay: boolean | undefined
}) {
  const t = useTranslations("storefront")
  const { setIsLoading } = useUbStorefrontState()
  const router = useRouter()

  const [preliminarySelectValue, setPreliminarySelectValue] = useState<string>("")

  const isFilterSelected = useMemo(() => {
    return (
      (props?.facetData?.selectedFacetElements &&
        props.facetData?.selectedFacetElements.length > 0 &&
        hasFFSearchParam(props.facetData, props.ffSearchParams)) ||
      preliminarySelectValue
    )
  }, [preliminarySelectValue, props.facetData, props.ffSearchParams])

  const computedSelectedValue = useMemo(() => {
    if (preliminarySelectValue !== "") {
      return preliminarySelectValue + " " + (props.facetData?.unit ?? "")
    } else {
      return props.facetData?.selectedFacetElements
        .map((facetElement: FacetElementData) => {
          return facetElement.elementname + " " + (props.facetData?.unit ?? "")
        })
        .join(",")
    }
  }, [preliminarySelectValue, props.facetData])

  const handleSelect: (allElemData: FacetElementData[], currentElemData: FacetElementData, link: string) => void =
    useCallback(
      (allElemData: FacetElementData[], currentElemData: FacetElementData, link: string) => {
        //console.dir(allElemData)
        const existingItem = allElemData.find((element) => element.elementname === currentElemData.elementname)
        const newSelectValue = existingItem ? !existingItem.selected : true
        allElemData.forEach((item) => {
          item.selected = false
        })
        currentElemData.selected = newSelectValue
        if (newSelectValue) {
          setPreliminarySelectValue(currentElemData.elementname)
        } else {
          setPreliminarySelectValue("")
        }
        setIsLoading(true)
        router.push(link)
      },
      [router, setIsLoading]
    )

  function removeSelected() {
    setPreliminarySelectValue("")
    setIsLoading(true)
    router.push(
      getResetSingleAttributeLinkToSearchResultOrCategory(
        props.isoLocale,
        props.facetData?.fieldName,
        props.ffSearchParams,
        props.query,
        props.baseCategory
      )
    )
  }

  return (
    <div className={"flex flex-col gap-2"} data-attribute={props.facetData?.fieldName ?? ""}>
      <div className={"flex flex-row justify-between gap-2"}>
        <div className={"flex flex-row items-center gap-2"}>
          <p
            className={cn("font-bold", {
              "text-primary": isFilterSelected,
            })}
          >
            {props?.customFilter?.name && props.customFilter.name !== ""
              ? props?.customFilter?.name
              : props.facetData?.name}
          </p>
          {/* Removed due to UBW-693
         {isFilterSelected && (
            <Button onClick={() => removeSelected()} variant={"ghost"} size={"tiny"}>
              <XCircleIcon className={"size-4 text-gray-400"} />
            </Button>
          )}*/}
        </div>
        <div className={"flex flex-row items-center"}>
          {props?.customFilter?.hint && props.customFilter.hint !== "" && (
            <TooltipProvider delayDuration={50} skipDelayDuration={100}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Info className={"size-4 text-gray-400"} />
                </TooltipTrigger>
                <TooltipContent
                  side={"top"}
                  sideOffset={2}
                  alignOffset={2}
                  className={"rounded-sm border border-gray-200 bg-white  max-w-72"}
                >
                  <p className={"text-sm text-gray-800"}>{props.customFilter.hint}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
      </div>
      <div>
        <div className={"flex flex-row items-center justify-start gap-4"}>
          {props?.customFilter?.icon && (
            <StoryblokAssetClient
              width={64}
              height={64}
              className={"ml-1 size-7 opacity-60"}
              asset={props.customFilter.icon}
            />
          )}

          <DropdownMenu modal={false}>
            <DropdownMenuTrigger asChild>
              <Button size={"defaultNoGrow"} variant={"secondary"} className={"w-full min-w-44 justify-between"}>
                <p>{isFilterSelected ? computedSelectedValue : t("common.filter_select")}</p>
                <ChevronDown className="-me-1 ms-2 opacity-60" size={16} strokeWidth={2} aria-hidden="true" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className={cn("max-h-96 min-w-[--radix-dropdown-menu-trigger-width] overflow-y-auto", {
                "z-[300]": props.overlay,
              })}
              onCloseAutoFocus={(e) => e.preventDefault()}
            >
              <DropdownMenuLabel>
                {" "}
                {isFilterSelected && (
                  <div
                    onClick={() => removeSelected()}
                    className="cursor-pointer flex min-w-40 flex-row items-center gap-1 hover:text-primary"
                  >
                    Zurücksetzen
                  </div>
                )}
              </DropdownMenuLabel>
              <CustomDropdownFacet
                isoLocale={props.isoLocale}
                query={props.query}
                baseCategory={props.baseCategory}
                facetData={props.facetData}
                customFilter={props.customFilter}
                handleSelect={handleSelect}
              />
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  )
}
