"use client"

import React, { useMemo } from "react"
import { useRouter } from "next/navigation"
import { useUbPdpState } from "@/store/UbpdpState"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { ChevronDown } from "lucide-react"

import { getInvalidCombinationMasterLink, isVariantPossible } from "@/lib/storefront/product/productHelper"
import { cn } from "@/lib/utils"
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/components"
import { BadgeFilterSelectorProps } from "@/components/product/pdp/filter/BadgeFilterSelector"
import { UseFilteredVariantsHook } from "@/components/product/pdp/UseFilteredVariantsHook"

export function DropdownFilterSelector(props: BadgeFilterSelectorProps) {
  const { selectedFilterOptions, selectOptionForAttribute, useClientSelectedFilterOptions } = useUbPdpState()
  const { setIsLoading } = useUbStorefrontState()

  const masterProduct = props.pdpContext.productAndArticle.masterProduct
  const router = useRouter()

  function handleOnSelect(id: string, value: string) {
    if (isVariantPossible(possibleVariants, selectedFilterOptions, id, value)) {
      selectOptionForAttribute(id, value)
    } else {
      if (props.pdpContext.masterProductLink) {
        setIsLoading(true)
        router.push(
          getInvalidCombinationMasterLink(props.pdpContext.masterProductLink ?? "", props.attribute.id, value)
        )
      }
    }
  }

  const possibleVariants = UseFilteredVariantsHook(masterProduct).possibleVariants

  const currentActiveValue = useMemo(() => {
    return useClientSelectedFilterOptions && selectedFilterOptions[props.attribute.id]
      ? selectedFilterOptions[props.attribute.id]
      : props.attribute.label
  }, [props.attribute.id, props.attribute.label, selectedFilterOptions, useClientSelectedFilterOptions])

  return (
    <div
      className={cn("flex flex-col gap-4", {
        hidden: !useClientSelectedFilterOptions,
      })}
    >
      <div className={"flex flex-col gap-2 pb-2"}>
        <span className={"font-bold"}>{props.attribute.label}</span>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button size={"defaultNoGrow"} variant={"default"} className={"bg-primary py-0 font-normal text-white"}>
              {currentActiveValue}
              <ChevronDown className="-me-1 ms-2 opacity-60" size={16} strokeWidth={2} aria-hidden="true" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="min-w-[--radix-dropdown-menu-trigger-width]">
            {props.values.map((value) => (
              <DropdownMenuItem
                onSelect={() => handleOnSelect(props.attribute.id, value.value.value)}
                key={"k" + value.value.value}
              >
                <span
                  className={cn("", {
                    "text-gray-200": !isVariantPossible(
                      possibleVariants,
                      selectedFilterOptions,
                      props.attribute.id,
                      value.value.value
                    ),
                  })}
                >
                  {value.value.value}
                </span>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>

        <div className={"flex flex-row flex-wrap gap-2"}></div>
      </div>
    </div>
  )
}
