"use client"

import React, { useMemo, useState } from "react"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { SearchParams } from "@ub/fact-finder-client"
import { XCircleIcon } from "lucide-react"
import { useTranslations } from "next-intl"

import { FacetData } from "@/lib/factfinder/factfinder-interfaces"
import { getResetLinkToSearchResultOrCategory } from "@/lib/link-utils/link-utils"
import { extractFacetsToHide } from "@/lib/sb-helper"
import { Category } from "@/lib/storefront/category/category"
import { AccordionContent, AccordionItem, AccordionRoot, AccordionTrigger, Button } from "@/components/ui/components"
import { extractAttributeNameFromFFFieldName, shouldRenderAsCustomFacet } from "@/components/ui/helpers/filterHelper"
import { cn } from "@/components/ui/helpers/utils"
import CategoryTree from "@/components/search-and-category/CategoryTree"
import { CustomFilterWrapper } from "@/components/search-and-category/customfilter/CustomFilterWrapper"
import { buildCustomFilterAttributesMap } from "@/components/search-and-category/customfilter/filterHelper"
import Facet from "@/components/search-and-category/Facet"
import { CategoryContentStoryblok, CustomFilterStoryblok } from "@/components/storyblok/component-types"

import { FacetForBrand } from "./FacetForBrand"
import { FacetForPrice } from "./FacetForPrice"

interface FacetListProps {
  defaultFacetsToHide: string[]
  facets: FacetData[]
  query?: string
  baseCategory?: Category
  brandSlug?: string
  categoryAsTree?: boolean
  categoryOnly?: boolean
  isFiltered: boolean
  hitcount: number
  categoryContent?: CategoryContentStoryblok
  customFilterEnabled?: boolean
  embedMode?: boolean
  ffSearchParams: SearchParams | undefined
  isoLocale: string
}

// see ub-intershop-intern: FilterList.isml
const FACET_COUNT_TO_SHOW_IN_SEARCH = 11
const FACET_COUNT_TO_SHOW_IN_CATEGORIES = 12
const FACET_COUNT_TO_SHOW_EXPANDED = 4

function categoryContentCustomFilters(categoryContent: CategoryContentStoryblok | undefined, query?: string) {
  return categoryContent &&
    categoryContent?.customFilterSpace &&
    categoryContent?.customFilterSpace[0]?.customFilterList
    ? categoryContent?.customFilterSpace[0]?.customFilterList
    : undefined
}

function sortFacetsConsideringCustomFilters(
  customFilterEnabled: boolean,
  categoryContent: CategoryContentStoryblok | undefined,
  facets: FacetData[],
  defaultFacetsToHide: string[],
  keepCustom: boolean
) {
  const customFilters: CustomFilterStoryblok[] | undefined = customFilterEnabled
    ? categoryContentCustomFilters(categoryContent)
    : []

  const hasCustomFilters = customFilters && customFilters.length > 0

  //The facets that should be invisible when rendering in custom mode
  const customFacetsToHide: string[] = categoryContent?.facetsToHide
    ? extractFacetsToHide(categoryContent?.facetsToHide)
    : []
  //console.dir(customFacetsToHide)
  //defaultFacetsToHide - The facets that should be invisible when rendering in normal mode

  const markedFacets: string[] = hasCustomFilters && customFilterEnabled ? customFacetsToHide : defaultFacetsToHide //Init with explicitly defined facets to hide
  const customFacets: string[] = []
  const sortedFacets: FacetData[] = []

  //iterate over custom filters, if they exist add their attribute names to the list of marked attributes
  if (hasCustomFilters && customFilterEnabled) {
    //Iterate over custom filters
    for (const filter of customFilters) {
      //Find the facet for the custom filter
      const facetForCustomFilter = facets.find((facetData: FacetData) => {
        return (
          filter?.active &&
          extractAttributeNameFromFFFieldName(facetData.fieldName) === filter?.attribute?.toLowerCase()
        )
      })
      //If the facet is found then...
      if (facetForCustomFilter) {
        // ...check if custom facet should be kept -> add to customFacets
        if (keepCustom) {
          customFacets.push(filter?.attribute?.toLowerCase() ?? "")
          // ... otherwise mark them
        } else {
          markedFacets.push(filter?.attribute?.toLowerCase() ?? "")
        }
      }
    }
  }

  //Iterate over all customFacetFields
  for (const facetFieldName of customFacets) {
    const facetForCustomFilter = facets.find((facetData: FacetData) => {
      return extractAttributeNameFromFFFieldName(facetData.fieldName) === facetFieldName
    })

    if (facetForCustomFilter) {
      sortedFacets.push(facetForCustomFilter)
    }
  }

  const FILTER_EXCLUSION_PATTERN = "_txt"
  const shouldExcludeByPattern = (facetFieldName: string) => {
    return !!(facetFieldName && facetFieldName.length > 0 && facetFieldName.includes(FILTER_EXCLUSION_PATTERN))
  }

  //Iterate again over all facets
  for (const facet of facets) {
    const facetFieldName = extractAttributeNameFromFFFieldName(facet.fieldName)
    //Push to other facets if not marked and not already in customFacets
    //console.dir(facetFieldName)
    if (
      facet &&
      !markedFacets.includes(facetFieldName) &&
      !customFacets.includes(facetFieldName) &&
      !shouldExcludeByPattern(facetFieldName)
    ) {
      //console.log("Pushing other facets: " + facet.fieldName)
      sortedFacets.push(facet)
    }
  }

  //console.dir(sortedFacets)

  return sortedFacets
}

export default function FacetList(props: FacetListProps) {
  const t = useTranslations("storefront")
  const [showAll, setShowAll] = useState(false)
  const { filterShown, setFilterShown } = useUbStorefrontState()

  const customFilterEnabled: boolean = useMemo(() => {
    return props.customFilterEnabled ?? false
  }, [props.customFilterEnabled])

  const sortedFacets: FacetData[] = useMemo(() => {
    return sortFacetsConsideringCustomFilters(
      customFilterEnabled,
      props.categoryContent,
      props.facets,
      props.defaultFacetsToHide,
      !(props.embedMode ?? false)
    )
  }, [customFilterEnabled, props.categoryContent, props.defaultFacetsToHide, props.embedMode, props.facets])

  const customFilterAttributes: Record<string, CustomFilterStoryblok> = useMemo(() => {
    return buildCustomFilterAttributesMap(customFilterEnabled, categoryContentCustomFilters(props.categoryContent))
  }, [customFilterEnabled, props.categoryContent])

  const linkToResetAllFilters: string = useMemo(() => {
    return getResetLinkToSearchResultOrCategory(props.query, props.baseCategory, props.brandSlug, props.isoLocale)
  }, [props.baseCategory, props.brandSlug, props.isoLocale, props.query])

  const openFacets: string[] = useMemo(() => {
    const firstNonCustomFacets: string[] = []
    for (const facet of sortedFacets) {
      if (!customFilterAttributes[extractAttributeNameFromFFFieldName(facet.fieldName)]) {
        firstNonCustomFacets.push(facet.name)
      }
    }
    return firstNonCustomFacets
  }, [customFilterAttributes, sortedFacets])

  const hasAnyFacets: boolean = useMemo(() => {
    return !!sortedFacets?.length
  }, [sortedFacets?.length])

  const needResetFilterButton: boolean = useMemo(() => {
    let nr = !hasAnyFacets || props.isFiltered

    if (props.hitcount <= 0 && !props.isFiltered) {
      nr = false
    }
    return nr
  }, [hasAnyFacets, props.hitcount, props.isFiltered])

  const initialFacetCountToShow: number = useMemo(() => {
    return props.baseCategory ? FACET_COUNT_TO_SHOW_IN_CATEGORIES : FACET_COUNT_TO_SHOW_IN_SEARCH
  }, [props.baseCategory])

  const hiddenCount: number = useMemo(() => {
    return (sortedFacets?.length || 0) - initialFacetCountToShow
  }, [initialFacetCountToShow, sortedFacets?.length])

  const facetCountToShow: number = useMemo(() => {
    return hiddenCount <= 1 ? Infinity : initialFacetCountToShow
  }, [hiddenCount, initialFacetCountToShow])

  function handleCloseFilter() {
    setFilterShown(false)
  }

  return (
    <div
      className={cn("hidden lg:block mb-24", {
        block: filterShown,
      })}
    >
      <div
        className={cn(
          "fixed inset-x-0 top-0 hidden h-12 items-center justify-center border-b border-b-gray-200 bg-white",
          {
            "flex z-[301] lg:hidden": filterShown,
          }
        )}
      >
        <p className={"text-lg font-bold"}> {t("common.filter")}</p>
        <XCircleIcon
          className={cn("absolute right-0 top-1 m-2 hidden size-6 cursor-pointer bg-white lg:hidden", {
            block: filterShown,
          })}
          onClick={handleCloseFilter}
        />
      </div>

      <div
        className={cn(
          "fixed inset-x-0 bottom-32 top-12 hidden bg-ub-background lg:static lg:block lg:overflow-y-auto",
          {
            "block z-[300]  overflow-y-scroll": filterShown,
          }
        )}
      >
        <div className="relative flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            {needResetFilterButton && !hasAnyFacets && (
              <a className="mb-4 hidden lg:block " href={linkToResetAllFilters}>
                {t("common.reset_filters")}
              </a>
            )}

            {hasAnyFacets && (
              <AccordionRoot
                className={cn("min-w-80 max-w-80 pt-2", {
                  "max-w-full pt-0": filterShown || props.embedMode,
                  "pl-4 pr-2": props.embedMode,
                })}
                type="multiple"
                defaultValue={openFacets}
              >
                {needResetFilterButton && (
                  <a className="mb-4 hidden lg:block" href={linkToResetAllFilters}>
                    {t("common.reset_filters")}
                  </a>
                )}

                <div>
                  {sortedFacets &&
                    sortedFacets.map((facet: FacetData, index: number) => (
                      <div
                        key={index}
                        className={
                          ((index < facetCountToShow || showAll) && !props.categoryOnly) ||
                          (props.categoryOnly && index === 0)
                            ? "block"
                            : "hidden"
                        }
                      >
                        {!shouldRenderAsCustomFacet(facet, customFilterAttributes) ? (
                          <>
                            <FacetListItem index={index} facet={facet} {...props} />
                          </>
                        ) : (
                          <>
                            {filterShown && (
                              <div
                                data-attribute={facet.fieldName}
                                className="mb-4 rounded-sm border border-gray-200 bg-white p-2 font-bold"
                              >
                                <CustomFilterWrapper
                                  isoLocale={props.isoLocale}
                                  customFilter={
                                    customFilterAttributes[extractAttributeNameFromFFFieldName(facet.fieldName)]
                                  }
                                  query={props.query}
                                  baseCategory={props.baseCategory}
                                  facetData={facet}
                                  ffSearchParams={props.ffSearchParams}
                                  overlay={true}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    ))}
                  <div
                    className={cn("block", {
                      hidden: props.categoryOnly,
                    })}
                  >
                    {sortedFacets && sortedFacets.length > facetCountToShow && (
                      <button
                        className="my-2 w-full border border-gray-200 bg-white p-2 text-xs text-gray-500 lg:p-4"
                        onClick={() => setShowAll(!showAll)}
                      >
                        {showAll ? t("common.show_less") : t("common.show_further_count", { count: hiddenCount })}
                      </button>
                    )}
                  </div>
                </div>
              </AccordionRoot>
            )}
          </div>
        </div>
      </div>
      <div
        className={cn(
          "fixed inset-x-0 bottom-0 hidden h-32 flex-col items-center justify-center gap-2 border-t border-t-gray-200 bg-white px-8 ",
          {
            "flex z-[300] lg:hidden": filterShown,
          }
        )}
      >
        <Button className={"w-full"} size={"lg"} onClick={handleCloseFilter}>
          {t("common.apply_filters", { hitcount: props.hitcount })}
        </Button>

        <a className={"mb-4 block w-full"} href={linkToResetAllFilters}>
          <Button className={"w-full"} size={"lg"} variant={needResetFilterButton && !hasAnyFacets ? "ghost" : "black"}>
            {t("common.reset_filters")}
          </Button>
        </a>
      </div>
    </div>
  )
}

interface FacetListItemProps {
  index: number
  facet: FacetData
  query?: string
  baseCategory?: Category
  brandSlug?: string
  categoryAsTree?: boolean
  isoLocale: string
}

/*Could be
 a FacetForBrand filter OR
 a FacetForPrice filter OR
 a CategoryTree filter OR
 a default Facet filter*/
function FacetListItem({ index, facet, ...props }: FacetListItemProps) {
  const isBrand = facet.fieldName === "brand"
  const isPrice = facet.fieldName === "price"
  const isCategory = facet.fieldName === "category"
  const showCategoryTree = props.categoryAsTree && isCategory
  const t = useTranslations("storefront")

  return (
    <AccordionItem
      data-attribute={facet.fieldName}
      key={index}
      className="mb-4 rounded-sm border border-gray-200 bg-white p-2 font-bold"
      value={facet.name}
    >
      <AccordionTrigger className="h-10 px-1 text-start shadow-none">
        {isCategory ? t("suggestion.result.header.categories") : facet.name}
      </AccordionTrigger>
      <AccordionContent className="text-sm font-normal">
        {isBrand && (
          <FacetForBrand
            isoLocale={props.isoLocale}
            query={props.query}
            baseCategory={props.baseCategory}
            brandSlug={props.brandSlug}
            facetData={facet}
          />
        )}
        {isPrice && (
          <FacetForPrice
            isoLocale={props.isoLocale}
            query={props.query}
            baseCategory={props.baseCategory}
            brandSlug={props.brandSlug}
            facetData={facet}
          />
        )}
        {showCategoryTree && props.baseCategory && (
          <CategoryTree category={props.baseCategory} facet={facet} sbLocale={props.isoLocale} />
        )}{" "}
        {/*TODO sbLocale!=isoLocale*/}
        {!isBrand && !isPrice && !showCategoryTree && (
          <Facet
            isoLocale={props.isoLocale}
            query={props.query}
            baseCategory={props.baseCategory}
            brandSlug={props.brandSlug}
            facetData={facet}
          />
        )}
      </AccordionContent>
    </AccordionItem>
  )
}
