import { FacetElementData } from "@/lib/factfinder/factfinder-interfaces"

export function noSort(a: FacetElementData, b: FacetElementData): number {
  return 1
}

export function sortByHitCount(a: FacetElementData, b: FacetElementData): number {
  return b.hitcount - a.hitcount
}

export function sortByAlphanumeric(a: FacetElementData, b: FacetElementData): number {
  if (a.elementname < b.elementname) {
    return -1
  }
  if (a.elementname > b.elementname) {
    return 1
  }
  return 0
}

export function sortBySelectedAlphanumeric(a: FacetElementData, b: FacetElementData): number {
  const hasHitsA: number = a.hitcount > 0 ? 1 : 0
  const hasHitsB: number = b.hitcount > 0 ? 1 : 0

  let compareAlpha = 0

  if (a.elementname < b.elementname) {
    compareAlpha = -1
  }
  if (a.elementname > b.elementname) {
    compareAlpha = 1
  }

  return hasHitsB - hasHitsA || compareAlpha
}

export function sortByNumeric(a: FacetElementData, b: FacetElementData): number {
  const numberStringA = a.elementname.replace(/\D/g, "")
  const numberStringB = b.elementname.replace(/\D/g, "")

  if (numberStringA !== "" && numberStringB !== "") {
    const numberA: number = Number.parseInt(numberStringA)
    const numberB: number = Number.parseInt(numberStringB)

    return numberA - numberB
  }
  return 0
}

export function sortBySelectedNumeric(a: FacetElementData, b: FacetElementData): number {
  const numberStringA = a.elementname.replace(/\D/g, "")
  const numberStringB = b.elementname.replace(/\D/g, "")

  if (numberStringA !== "" && numberStringB !== "") {
    const numberA: number = Number.parseInt(numberStringA)
    const numberB: number = Number.parseInt(numberStringB)

    const hasHitsA: number = a.hitcount > 0 ? 1 : 0
    const hasHitsB: number = b.hitcount > 0 ? 1 : 0

    return hasHitsB - hasHitsA || numberA - numberB
  }
  return 0
}

export function sortByMinShipmentDays(a: FacetElementData, b: FacetElementData): number {
  if (a.minShipmentDays === undefined) {
    return 1
  }
  if (b.minShipmentDays === undefined) {
    return -1
  }
  return a.minShipmentDays - b.minShipmentDays
}
