import SVG from "react-inlinesvg"

//Workaround for eliminating icon problems
export default function LucideIcon({ blok }) {
  return (
    <>
      {blok?.icon ? (
        <SVG
          src={"/img/icons/lucide/" + blok.icon.toLowerCase() + ".svg"}
          width={128}
          className={"size-6"}
          height="auto"
          title={blok?.icon}
        />
      ) : (
        <img className={"size-6"} src={"/img/icons/luicde/squircle.svg"} alt={""} />
      )}
    </>
  )
}
