import { FacetData } from "@/lib/factfinder/factfinder-interfaces"
import { CustomFilterStoryblok } from "@/components/storyblok/component-types"

export function extractAttributeNameFromFFFieldName(fieldName: string | undefined) {
  if (fieldName && fieldName.includes("~~")) {
    const split = fieldName.split("~~")
    const attribute = split[0].toLowerCase()
    //console.dir(attribute)
    return attribute
  }
  return fieldName?.toLowerCase() ?? ""
}

export function shouldRenderAsCustomFacet(
  facet: FacetData | undefined,
  customFilterAttributes: Record<string, CustomFilterStoryblok>
) {
  if (facet) {
    const fieldName = extractAttributeNameFromFFFieldName(facet.fieldName)
    //console.dir(fieldName)
    return customFilterAttributes[fieldName] && customFilterAttributes[fieldName]?.active
  } else {
    return false
  }
}
