export const withoutTrailingSlash = (url: string) => {
  return url.endsWith("/") ? url.slice(0, -1) : url
}

export const withoutStartingSlash = (url: string) => {
  return url.startsWith("/") ? url.slice(1) : url
}

export const ensureTrailingSlash = (url: string) => {
  return url.endsWith("/") ? url : url + "/"
}

export const ensureStartingSlash = (url: string) => {
  return url.startsWith("/") ? url : "/" + url
}
