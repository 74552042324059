"use client"

import React, { useMemo } from "react"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { SearchParams } from "@ub/fact-finder-client"

import { FacetData, FacetElementData } from "@/lib/factfinder/factfinder-interfaces"
import { Category } from "@/lib/storefront/category/category"
import { extractAttributeNameFromFFFieldName, shouldRenderAsCustomFacet } from "@/components/ui/helpers/filterHelper"
import { cn } from "@/components/ui/helpers/utils"
import ErrorBoundary from "@/components/layout/ErrorBoundary"
import { CustomFilterWrapper } from "@/components/search-and-category/customfilter/CustomFilterWrapper"
import {
  buildAllFacetsFilteredMap,
  buildCustomFilterAttributesMap,
} from "@/components/search-and-category/customfilter/filterHelper"
import FacetApplyFiltersButton from "@/components/search-and-category/FacetApplyFiltersButton"
import { CategoryContentStoryblok, CustomFilterStoryblok } from "@/components/storyblok/component-types"

interface CustomFacetListProps {
  facets: FacetData[]
  allFacets: FacetData[]
  query?: string
  baseCategory?: Category
  brandSlug?: string
  categoryAsTree?: boolean
  categoryOnly?: boolean
  isFiltered: boolean
  hitcount: number
  categoryContent?: CategoryContentStoryblok
  customFilterEnabled: boolean
  showAboveFilterButton: boolean
  ffSearchParams?: SearchParams | undefined
  isoLocale: string
}

function lookupFacet(facets: FacetData[], attribute: string) {
  return facets.find((facetData: FacetData) => {
    return extractAttributeNameFromFFFieldName(facetData.fieldName) === attribute.toLowerCase()
  })
}

function blendFacets(
  facets: FacetData[],
  customFilterEnabled: boolean,
  allFacetsFilteredMap: Record<string, FacetData>
) {
  if (!customFilterEnabled) {
    return facets
  } else {
    const newFacets: FacetData[] = []

    for (const facetName in allFacetsFilteredMap) {
      const facet = lookupFacet(facets, facetName)
      if (facet) {
        //console.log("Found facet:", facetName)

        const fullFacet = allFacetsFilteredMap[facetName]
        const newFacet = { ...facet }
        const newElems: FacetElementData[] = []
        const newSelElems: FacetElementData[] = []
        for (const allFacetElem of fullFacet.facetElements) {
          const facetElem = facet.facetElements.find((value) => {
            if (value.elementname === allFacetElem.elementname) {
              //console.log("Found facetElem element:", value)
              return value
            }
          })

          const selFacetElem = facet.selectedFacetElements.find((value) => {
            if (value.elementname === allFacetElem.elementname) {
              //console.log("Found selFacetElem element:", value)
              return value
            }
          })

          if (facetElem || selFacetElem) {
            if (facetElem) {
              newElems.push(facetElem)
            } else if (selFacetElem) {
              newSelElems.push(selFacetElem)
            }
          } else {
            newElems.push(allFacetElem)
          }
        }

        newFacet.facetElements = newElems
        newFacet.selectedFacetElements = newSelElems
        //console.dir(newFacet)
        newFacets.push(newFacet)
      } else {
        //console.log("Not found facet:", facetName)
        newFacets.push(allFacetsFilteredMap[facetName])
      }
    }

    return newFacets
  }
}

export default function CustomFacetList(props: CustomFacetListProps) {
  const customFilterList = useMemo(() => {
    return props.categoryContent?.customFilterSpace?.[0].customFilterList
  }, [props.categoryContent?.customFilterSpace])

  const allFacetsFilteredMap: Record<string, FacetData> = useMemo(() => {
    return buildAllFacetsFilteredMap(props.allFacets, customFilterList)
  }, [props.allFacets, customFilterList])

  const blendedFacets = useMemo(() => {
    return blendFacets(props.facets, props.customFilterEnabled, allFacetsFilteredMap)
  }, [allFacetsFilteredMap, props.customFilterEnabled, props.facets])

  const customFilterAttributes: Record<string, CustomFilterStoryblok> = useMemo(() => {
    return buildCustomFilterAttributesMap(props.customFilterEnabled, customFilterList)
  }, [props.customFilterEnabled, customFilterList])

  const hasAnyFacets = !!blendedFacets?.length
  let needResetFilterButton = !hasAnyFacets || props.isFiltered
  if (props.hitcount <= 0 && !props.isFiltered) {
    needResetFilterButton = false
  }

  function resolveFacetFromCustomFilter(facets: FacetData[], customFilter: CustomFilterStoryblok) {
    return facets.find((facetData: FacetData) => {
      return extractAttributeNameFromFFFieldName(facetData.fieldName) === (customFilter?.attribute ?? "").toLowerCase()
    })
  }

  //console.dir(allFacetsFilteredMap)

  const { filterShown } = useUbStorefrontState()

  return (
    <ErrorBoundary>
      <div
        className={cn("hidden grow md:block", {
          "pt-10": needResetFilterButton && !props.categoryContent?.hideDefaultFilter,
          block: props.showAboveFilterButton,
        })}
      >
        <div
          className={cn(
            "fixed inset-x-0 bottom-32 top-12 hidden bg-ub-background md:static md:block md:overflow-y-auto",
            {
              "block z-[300]  overflow-y-scroll": filterShown,
              "block  static overflow-y-auto": props.showAboveFilterButton,
            }
          )}
        >
          <div className="relative flex flex-col gap-4">
            {/*
            <pre className={"absolute inset-0 hidden"}>{JSON.stringify(props.allFacets)}</pre>
*/}
            <div className="flex flex-col gap-4">
              {hasAnyFacets && (
                <div className={"flex flex-row flex-wrap gap-2"}>
                  {customFilterList &&
                    customFilterList.map((customFilter: CustomFilterStoryblok, index: number) => (
                      <div
                        key={index}
                        className={cn("hidden", {
                          "block grow shrink": shouldRenderAsCustomFacet(
                            resolveFacetFromCustomFilter(blendedFacets, customFilter),
                            customFilterAttributes
                          ),
                          "w-full basis-1/4 min-w-64 max-w-[32rem]": customFilter?.type === "dropdown",
                        })}
                      >
                        {shouldRenderAsCustomFacet(
                          resolveFacetFromCustomFilter(blendedFacets, customFilter),
                          customFilterAttributes
                        ) && (
                          <div
                            data-attribute={resolveFacetFromCustomFilter(blendedFacets, customFilter)?.fieldName}
                            className="mb-4 rounded-sm border border-gray-200 bg-white p-2 font-bold shrink"
                          >
                            <CustomFilterWrapper
                              isoLocale={props.isoLocale}
                              overlay={false}
                              customFilter={
                                customFilterAttributes[
                                  extractAttributeNameFromFFFieldName(
                                    resolveFacetFromCustomFilter(blendedFacets, customFilter)?.fieldName
                                  )
                                ]
                              }
                              query={props.query}
                              baseCategory={props.baseCategory}
                              facetData={resolveFacetFromCustomFilter(blendedFacets, customFilter)}
                              ffSearchParams={props.ffSearchParams}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <FacetApplyFiltersButton hitcount={props.hitcount} />
      </div>
    </ErrorBoundary>
  )
}
