"use client"

import React, { FormHTMLAttributes, ReactNode } from "react"
import { useSearchParams } from "next/navigation"

interface FormWithSearchSearchParamsProps extends FormHTMLAttributes<HTMLFormElement> {
  children: ReactNode
  ownParams?: string[]
}

// Generates query string (URI) parameters as hidden fields
export function FormWithSearchParams({ children, ownParams = [], ...props }: FormWithSearchSearchParamsProps) {
  const searchParams = useSearchParams()
  const hasSearchParams: boolean = !!searchParams?.size

  return (
    <form {...props}>
      {searchParams &&
        hasSearchParams &&
        Array.from(searchParams.entries())
          .filter(([key]) => !ownParams?.includes(key))
          .map(([key, value]) => (
            <input type="hidden" key={key} name={key} value={Array.isArray(value) ? value.join(",") : value} />
          ))}
      {children}
    </form>
  )
}

export default FormWithSearchParams
