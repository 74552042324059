"use client"

import React, { Suspense, useEffect } from "react"
import { loadBasketLineItemsAction, loadBasketPromotionCodesAction } from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"

import { BasketContext } from "@/lib/storefront/basket/basket"
import { Skeleton } from "@/components/ui/components"
import BasketActions from "@/components/basket/BasketActions"
import BasketEmpty from "@/components/basket/BasketEmpty"
import BasketRecentlyViewedProducts from "@/components/basket/BasketRecentlyViewedProducts"
import { BasketLineItemList } from "@/components/basket/overview/BasketLineItemList"
import { UbBasketLineItemsStoryblok } from "@/components/storyblok/component-types"

interface BasketOverviewProps {
  basketContext: BasketContext
  blok: UbBasketLineItemsStoryblok
}

export default function BasketLineItems(props: BasketOverviewProps) {
  const isoLocale = props.basketContext.isoLocale
  const isB2B = props.basketContext.isB2B
  const userData = props.basketContext.userData
  const customerData = props.basketContext.customerData

  const { basketData, basketLineItems, setBasketLineItems, setBasketPromotionCodes } = useUbStorefrontState()
  /*
  useEffect(() => {
    console.dir(basketLineItems)
    console.dir(basketData)
  }, [basketData, basketLineItems])*/

  useEffect(() => {
    if (basketData) {
      loadBasketLineItemsAction(isoLocale, isB2B, userData, basketData).then((lineItems) => {
        if (lineItems) {
          lineItems.sort((a, b) => (a.position > b.position ? 1 : -1))
          //console.dir(lineItems, {depth: 9})

          setBasketLineItems(lineItems)
        }
      })
    }
  }, [basketData, isB2B, setBasketLineItems])

  useEffect(() => {
    if (basketData) {
      loadBasketPromotionCodesAction().then((promotionCodes) => {
        if (promotionCodes) {
          setBasketPromotionCodes(promotionCodes)
        }
      })
    }
  }, [basketData, isB2B, setBasketPromotionCodes])

  return (
    <div className={"flex min-h-96 w-full flex-col gap-2"}>
      <Suspense>
        <>
          {basketData &&
          (basketData?.data?.totalProductQuantity ?? 0) > 0 &&
          basketLineItems &&
          basketLineItems.length > 0 ? (
            <>
              <div className={"grid w-full grid-cols-1 gap-y-2"}>
                <div className={"col-span-2"}>
                  <div className={"flex flex-col gap-2"}>
                    <BasketLineItemList isB2B={isB2B} basketLineItems={basketLineItems} />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div>
              {(basketData && (basketData?.data?.totalProductQuantity ?? 0) < 1) || basketData === undefined ? (
                <BasketEmpty blok={props.blok} />
              ) : (
                <div className="flex flex-row gap-4">
                  <Skeleton className="h-96 w-2/3" />
                </div>
              )}
            </div>
          )}
        </>
      </Suspense>

      <div className={"grid w-full grid-cols-1 gap-8 lg:grid-cols-2"}></div>

      <div className={"w-full"}>
        <BasketActions
          customerData={customerData}
          userData={userData}
          blok={props.blok}
          isB2B={isB2B}
          basketLineItems={basketLineItems}
        />
      </div>

      <div className={"w-full"}>
        <BasketRecentlyViewedProducts />
      </div>
    </div>
  )
}
