import React, { useEffect, useLayoutEffect, useMemo, useState } from "react"
import { useUbPdpState } from "@/store/UbpdpState"
import { ProductPriceContainerCtnrROV1, ProductPriceContainerRO } from "@ub/pricing-client"
import { MinusIcon, PlusIcon } from "lucide-react"
import { useTranslations } from "next-intl"

import { ReducedProductData } from "@/lib/storefront/product/product"
import { cn } from "@/components/ui/helpers/utils"
import ProductCardAddToBasket from "@/components/product/ProductCardAddToBasket"

export interface AddToBaskedWithQuantityInputProps {
  productSku: string
  article?: ReducedProductData
  prices: ProductPriceContainerRO | undefined
  minOrderQuantity?: number
  orderQuantitySteps?: number
  compact?: boolean
  localState: boolean
}

export function AddToBaskedWithQuantityInput({
  article,
  prices,
  minOrderQuantity,
  orderQuantitySteps,
  productSku,
  compact,
  localState,
}: AddToBaskedWithQuantityInputProps) {
  const minimumOrderQuantity = minOrderQuantity ?? 1
  const quantitySteps = orderQuantitySteps ?? 1

  const { buyboxQuantity, setBuyboxQuantity } = useUbPdpState()

  const initialQuantity = buyboxQuantity ?? Math.min(minimumOrderQuantity, quantitySteps)

  const [localQuantity, setLocalQuantity] = useState<number>(initialQuantity)
  const [visibleQuantityField, setVisibleQuantityField] = useState<string>(initialQuantity.toString())

  const setQuantity = localState ? setLocalQuantity : setBuyboxQuantity

  const quantity = useMemo(() => {
    return localState ? localQuantity : buyboxQuantity
  }, [localState, localQuantity, buyboxQuantity])

  const priceContainer: ProductPriceContainerCtnrROV1 | undefined = prices
    ? {
        data: [prices],
      }
    : undefined

  useLayoutEffect(() => {
    updateAndShowQuantity(initialQuantity)
  }, [])

  useEffect(() => {
    if (!localState) {
      updateAndShowQuantity(buyboxQuantity)
    }
  }, [buyboxQuantity])

  const t = useTranslations("storefront")

  function decreaseQuantity() {
    updateAndShowQuantity(quantity - (quantitySteps ?? 1))
  }

  function increaseQuantity() {
    updateAndShowQuantity(quantity + (quantitySteps ?? 1))
  }

  function handleQuantityBlur(event: React.FocusEvent<HTMLInputElement>) {
    // reflect interally calculated quantity in visibleQuantityField
    setVisibleQuantityField(quantity.toString())
  }

  function handleQuantityChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value

    setVisibleQuantityField(value)

    if (/^\d*$/.test(value)) {
      if (value === "") {
        updateQuantity(1)
        return
      }

      const intValue = parseInt(value)
      updateQuantity(intValue)
    }
  }

  function updateQuantity(wantedQuantity: number, showVisible = false) {
    if (!(wantedQuantity > 0)) {
      return
    }

    const newAddableQuantity = wantedQuantity - (wantedQuantity % quantitySteps)
    const newQuantity = Math.max(minimumOrderQuantity, newAddableQuantity)
    setQuantity(newQuantity)

    if (showVisible) {
      setVisibleQuantityField(newQuantity.toString())
    }
  }

  function updateAndShowQuantity(newQuantity: number) {
    updateQuantity(newQuantity, true)
  }

  return (
    <>
      <div
        className={cn(
          "flex shrink-0 flex-row items-center justify-center rounded-lg bg-gray-100 p-0",
          { "w-auto lg:w-auto min-w-32 px-2": compact },
          { "w-auto lg:w-2/6": !compact }
        )}
      >
        <button
          className={cn("w-auto rounded-full py-1 pl-4 pr-2")}
          onClick={() => decreaseQuantity()}
          aria-label={t("product.quantity_decrease")}
        >
          <MinusIcon className={"size-5"} />
        </button>
        <input
          type="text"
          aria-label={t("product.quantity")}
          className={cn(
            "h-9 border-transparent bg-transparent p-0 py-1 text-center text-lg font-bold focus:rounded-md focus:border-2 focus:border-blue-400 focus:outline-none focus:ring-transparent",
            { "w-12": compact },
            { "w-16 grow": !compact }
          )}
          value={visibleQuantityField}
          onChange={handleQuantityChange}
          onBlur={handleQuantityBlur}
        />
        <button
          className={cn("w-auto rounded-full py-1 pl-2 pr-4")}
          onClick={() => increaseQuantity()}
          aria-label={"product.quantity_increase"}
        >
          <PlusIcon className={"size-5"} />
        </button>
      </div>

      {compact ? (
        <div className={"w-full"}>
          <ProductCardAddToBasket
            className={"w-full min-w-16"}
            articleIdToAdd={productSku}
            productData={article}
            prices={priceContainer}
            quantity={quantity}
          />
        </div>
      ) : (
        <>
          <div className={"ml-auto w-full lg:hidden"}>
            <ProductCardAddToBasket
              articleIdToAdd={productSku}
              productData={article}
              prices={priceContainer}
              quantity={quantity}
            />
          </div>
          <div className={"ml-auto hidden w-full lg:block"}>
            <ProductCardAddToBasket
              className={"w-full gap-2"}
              label={t("product.add_to_basket")}
              articleIdToAdd={productSku}
              productData={article}
              prices={priceContainer}
              quantity={quantity}
            />
          </div>
        </>
      )}
    </>
  )
}
