import React from "react"
import { SearchParams } from "@ub/fact-finder-client"

import { FacetData } from "@/lib/factfinder/factfinder-interfaces"
import { Category } from "@/lib/storefront/category/category"
import { CustomBadgeFilterWidget } from "@/components/search-and-category/customfilter/badge/CustomBadgeFilterWidget"
import { CustomDropdownFilterWidget } from "@/components/search-and-category/customfilter/dropdown/CustomDropdownFilterWidget"
import { CustomFilterStoryblok } from "@/components/storyblok/component-types"

export function CustomFilterWrapper(props: {
  isoLocale: string
  customFilter: CustomFilterStoryblok
  query: string | undefined
  baseCategory: Category | undefined
  facetData: FacetData | undefined
  ffSearchParams: SearchParams | undefined
  overlay: boolean | undefined
}) {
  return (
    <>
      {props.customFilter?.type === "badges" ? (
        <CustomBadgeFilterWidget
          isoLocale={props.isoLocale}
          customFilter={props.customFilter}
          query={props.query}
          baseCategory={props.baseCategory}
          facetData={props.facetData}
          ffSearchParams={props.ffSearchParams}
        />
      ) : (
        <CustomDropdownFilterWidget
          isoLocale={props.isoLocale}
          overlay={props.overlay}
          customFilter={props.customFilter}
          query={props.query}
          baseCategory={props.baseCategory}
          facetData={props.facetData}
          ffSearchParams={props.ffSearchParams}
        />
      )}
    </>
  )
}
