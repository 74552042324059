import { TableStoryblok } from "@/components/storyblok/component-types"

export function extractFacetsToHide(facetsToHideTable: TableStoryblok | undefined) {
  /* Example Data

  "facetsToHide": {
        "tbody": [
          {
            "_uid": "90d9aebb-b285-4c7a-8f97-c6beb12d076f",
            "body": [
              {
                "_uid": "e1b4ddb1-3c2a-4f69-a041-037a05f4206e",
                "value": "searchFilterAttributes (tiefe_mm)",
                "component": "_table_col",
                "_editable": "\u003C!--#storyblok#{\"name\": \"_table_col\", \"space\": \"276493\", \"uid\": \"e1b4ddb1-3c2a-4f69-a041-037a05f4206e\", \"id\": \"628238649\"}--\u003E"
              }
            ],
            "component": "_table_row",
            "_editable": "\u003C!--#storyblok#{\"name\": \"_table_row\", \"space\": \"276493\", \"uid\": \"90d9aebb-b285-4c7a-8f97-c6beb12d076f\", \"id\": \"628238649\"}--\u003E"
          },
          {
            "_uid": "03b5a19d-ae39-4c6e-b94e-6fabaf372e4e",
            "body": [
              {
                "_uid": "870e6c76-3853-4660-92cf-3998e61321be",
                "value": "searchFilterAttributes (breite_mm)",
                "component": "_table_col",
                "_editable": "\u003C!--#storyblok#{\"name\": \"_table_col\", \"space\": \"276493\", \"uid\": \"870e6c76-3853-4660-92cf-3998e61321be\", \"id\": \"628238649\"}--\u003E"
              }
            ],
            "component": "_table_row",
            "_editable": "\u003C!--#storyblok#{\"name\": \"_table_row\", \"space\": \"276493\", \"uid\": \"03b5a19d-ae39-4c6e-b94e-6fabaf372e4e\", \"id\": \"628238649\"}--\u003E"
          }
        ],
        "thead": [
          {
            "_uid": "e37ba674-7035-412b-bda4-294adcbdcf17",
            "value": "Fieldname",
            "component": "_table_head",
            "_editable": "\u003C!--#storyblok#{\"name\": \"_table_head\", \"space\": \"276493\", \"uid\": \"e37ba674-7035-412b-bda4-294adcbdcf17\", \"id\": \"628238649\"}--\u003E"
          }
        ],
        "fieldtype": "table"
      },

  * */

  const result: string[] = []

  if (facetsToHideTable?.tbody) {
    facetsToHideTable.tbody.forEach((tbody) => {
      if (tbody?.body && tbody?.body[0] && tbody?.body[0].value) {
        result.push(tbody?.body[0].value.toLowerCase())
      }
    })
  }

  return result
}
