"use client"

import { useUbPdpState } from "@/store/UbpdpState"
import { useTranslations } from "next-intl"

import { formatPrice } from "@/lib/helper"
import { CardPrices, determineCardPrices, formatPackagingUnit } from "@/lib/price-utils"
import { PdpContext } from "@/lib/storefront/product/pdp-service"

function ScalePriceLine(props: {
  threshold: number
  unit: string
  pricePerUnit: number
  currency: string
  isoLocale: string
}) {
  const t = useTranslations("storefront")
  const totalPrice = formatPrice(props.isoLocale, props.pricePerUnit * props.threshold, props.currency)
  const pricePerUnit = formatPrice(props.isoLocale, props.pricePerUnit, props.currency)
  // TODO correctly build singular und plural for props.unit
  return (
    <>
      <div className="flex flex-row gap-x-1 border-t border-t-gray-200 px-2 py-1 font-bold">
        <span>{t("product.price_from")}</span>
        <span>{props.threshold}</span>
        <span>{props.unit}</span>
      </div>
      <div className="border-l border-t border-t-gray-200 p-1">{totalPrice + " "}</div>
      <div className="flex flex-row border-t border-t-gray-200 p-1">
        <span className="font-bold">{pricePerUnit}</span>
        <span className="px-1">{t("product.per")}</span>
        {props.unit}
      </div>
    </>
  )
}

export default function ProductDetailBuyboxScalePrice(props: {
  pdpContext: PdpContext
  showEcoTaxFlag: boolean | undefined
}) {
  const t = useTranslations("storefront")
  const { setBuyboxQuantity } = useUbPdpState()
  const cardPrices: CardPrices = determineCardPrices(
    props.pdpContext.isoLocale,
    props.pdpContext.isB2B,
    props.pdpContext.prices
  )
  const scalePrices = cardPrices.scalePrices
  const packagingUnitInfo = ((): string => {
    const unitInfo = formatPackagingUnit(t, props.pdpContext.productAndArticle.article?.packingUnit ?? "PCE")
    return unitInfo?.length ? unitInfo : t("packaging_unit.PCE")
  })()

  const handleClick = (quantity: number) => {
    setBuyboxQuantity(quantity)
  }

  return (
    !(props.pdpContext.pdpViewMode === "MASTER_PRODUCT") &&
    scalePrices &&
    scalePrices?.length > 0 && (
      <div className="flex flex-col text-sm">
        <div className="grid-cols-[repeat(3,auto))] grid rounded-sm border border-gray-200">
          <div className="col-span-3 px-2 py-1 font-bold">{t("product.volume_discount")}</div>
          {scalePrices.map((scalePrice, index) => (
            <button
              className="col-span-3 grid cursor-pointer grid-cols-subgrid hover:text-primary"
              key={index}
              onClick={() => handleClick(scalePrice.minQuantity)}
            >
              <ScalePriceLine
                isoLocale={props.pdpContext.isoLocale}
                threshold={scalePrice.minQuantity}
                pricePerUnit={scalePrice.pricePerUnit}
                unit={packagingUnitInfo ?? ""}
                currency={scalePrice.currency ?? ""}
              />
            </button>
          ))}
        </div>
      </div>
    )
  )
}
