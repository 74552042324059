"use client"

import React, { useMemo } from "react"
import { OrderRO } from "@ub/order-client"
import { useTranslations } from "next-intl"

import { formatDate, formatPrice } from "@/lib/helper"
import { AccountContext } from "@/lib/storefront/account/account-service"
import {
  Button,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/components"

interface AccountOrderListClientsProps {
  accountContext: AccountContext
  initialOrders: OrderRO[] | undefined
}

const sortByDateAsc = (a, b) => {
  return (parseInt(a?.creationDate?.toString() ?? "0") ?? 0) - (parseInt(b?.creationDate?.toString() ?? "0") ?? 0)
}

const sortByDateDesc = (a, b) => {
  return (parseInt(b?.creationDate?.toString() ?? "0") ?? 0) - (parseInt(a?.creationDate?.toString() ?? "0") ?? 0)
}

const sortByPriceAsc = (a, b) => {
  return a?.payments[0]?.paymentTotalAmount?.value - b?.payments[0]?.paymentTotalAmount?.value
}

const sortByPriceDesc = (a, b) => {
  return b?.payments[0]?.paymentTotalAmount?.value - a?.payments[0]?.paymentTotalAmount?.value
}

function DashboardOrderCard(props: { order: OrderRO; isoLocale: string }) {
  return (
    <div className={"flex w-full flex-col gap-2"}>
      <div className={"flex w-full flex-row justify-between gap-2"}>
        <div className={"flex flex-col gap-2"}>
          <p>Bestellnummer</p>
          <p>{props.order.documentNo}</p>
        </div>
        <div className={"flex flex-col gap-2"}>
          <p>Bestelldatum</p>
          <p>{formatDate(props.isoLocale, props.order?.creationDate)}</p>
        </div>
        <div className={"flex flex-col gap-2"}>
          <p>Preis</p>
          <p>
            {props.order?.payments
              ? formatPrice(
                  props.isoLocale,
                  props.order?.payments[0]?.paymentTotalAmount?.value,
                  props.order?.payments[0]?.paymentTotalAmount?.currency
                  //order?.totals?.orderTotal?.value,
                  //order?.totals?.orderTotal?.currency
                )
              : ""}
          </p>
        </div>
      </div>
      <Button size={"smNoGrow"} type="submit">
        Erneut Bestellen
      </Button>
    </div>
  )
}

const SORT_BY_DATE_ASC = "SORT_BY_DATE_ASC"

export default function AccountOrderListClient(props: AccountOrderListClientsProps) {
  const accountContext: AccountContext = props.accountContext
  const t = useTranslations("storefront")

  const [selectedSort, setSelectedSort] = React.useState<string>(SORT_BY_DATE_ASC)

  const [selectedFilter, setSelectedFilter] = React.useState<string>("-")

  const [orders, setOrders] = React.useState<OrderRO[]>(props.initialOrders ?? [])

  const years: string[] = useMemo(() => {
    return ["-", "2025", "2024", "2023", "2022", "2021", "2020", "2019", "2018"]
  }, [])

  const sortFunctions = useMemo(() => {
    return {
      SORT_BY_DATE_ASC: sortByDateAsc,
      SORT_BY_DATE_DESC: sortByDateDesc,
      SORT_BY_PRICE_ASC: sortByPriceAsc,
      SORT_BY_PRICE_DESC: sortByPriceDesc,
    }
  }, [])

  const sortFunction = useMemo(() => {
    return sortFunctions[selectedSort]
  }, [selectedSort, sortFunctions])

  const filteredAndSortedOrders = useMemo(() => {
    if (orders) {
      return orders.sort(sortFunction)
    } else {
      return []
    }
  }, [orders, sortFunction])

  function handleSelectSort(currentSort: string) {
    setSelectedSort(currentSort)
  }

  function handleSelectFilter(currentFilter: string) {
    setSelectedFilter(currentFilter)
  }

  return (
    <>
      <div className={"flex flex-col gap-4"}>
        <div className={"flex flex-row items-start justify-between gap-4"}>
          <div className={"flex w-full flex-col gap-4"}>
            <p>Hier finden Sie Ihre Bestellungen.</p>
            <div className={"flex flex-row items-center"}>
              <Select onValueChange={handleSelectSort} value={selectedSort}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {Object.keys(sortFunctions).map((currentSort: string) => (
                      <SelectItem value={currentSort} key={currentSort} onClick={() => handleSelectSort(currentSort)}>
                        {t("sort_order." + currentSort)}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className={"flex w-full flex-col gap-4"}>
            <div>22 Bestellungen aufgegeben in</div>
            <div className={"flex flex-row items-center justify-end gap-2"}>
              <Select onValueChange={handleSelectFilter} value={selectedFilter}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {years.map((currentFilter: string) => (
                      <SelectItem
                        value={currentFilter}
                        key={currentFilter}
                        onClick={() => handleSelectFilter(currentFilter)}
                      >
                        {currentFilter}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>

        <div className={"flex flex-col items-end gap-2"}>
          {filteredAndSortedOrders?.map((order) => (
            <div key={order.documentNo} className={"flex w-full flex-col gap-1 rounded-sm border border-gray-200 p-2"}>
              <DashboardOrderCard order={order} isoLocale={props.accountContext.isoLocale} />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
