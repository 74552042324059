"use client"

import DOMPurify from "isomorphic-dompurify"

import { PdpContext } from "@/lib/storefront/product/pdp-service"
import { determineEffectiveProductFromPdpContext } from "@/lib/storefront/product/productHelper"
import ProductDetailPrintCatalogLink from "@/components/product/pdp/ProductDetailPrintCatalogLink"
import { UbProductDetailViewStoryblok } from "@/components/storyblok/component-types"

type ProductDetailShortDescriptionProps = {
  pdpContext: PdpContext
  blok: UbProductDetailViewStoryblok
}
export default function ProductDetailShortDescription({ pdpContext, blok }: ProductDetailShortDescriptionProps) {
  const articleOrMasterProduct = determineEffectiveProductFromPdpContext(pdpContext)

  return (
    articleOrMasterProduct && (
      <div className="rounded-sm border bg-white p-2 sm:p-6">
        <p className={"text-lg font-bold"}>{blok?.label_product_description_short}</p>
        <div className="prose">
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(articleOrMasterProduct.description || ""),
            }}
          />
        </div>
        <div
          onClick={() => {
            const element = document.getElementById("productDescriptionAnchor")
            element?.scrollIntoView({
              behavior: "smooth",
            })
          }}
          className={"mt-2 cursor-pointer text-sm font-bold text-primary"}
        >
          {blok?.label_jump_to_description}
        </div>
        <div className="mt-2">
          <ProductDetailPrintCatalogLink pdpContext={pdpContext} blok={blok} />
        </div>
      </div>
    )
  )
}
