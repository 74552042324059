import { useMemo } from "react"
import { useUbPdpState } from "@/store/UbpdpState"

import { AttributeValueData, FullMasterProductData, VariationProductData } from "@/lib/storefront/product/product"

export const UseFilteredVariantsHook = (masterProduct: FullMasterProductData | undefined) => {
  const { selectedFilterOptions, variantSortAsc } = useUbPdpState()

  const filteredVariants: VariationProductData[] = useMemo(() => {
    if (masterProduct && masterProduct.variants) {
      return masterProduct.variants
        .filter((v) => {
          let isInList = true
          for (const sfoAttribute in selectedFilterOptions) {
            if (isInList) {
              const sfoAttributeValue: string | undefined = selectedFilterOptions[sfoAttribute]

              const attribute = v.attributes.find((avd: AttributeValueData) => {
                //console.log(avd.attributeId)

                return avd.attributeId === sfoAttribute
              })

              if (attribute?.attributeId === sfoAttribute) {
                if (sfoAttributeValue && attribute?.value !== sfoAttributeValue) {
                  isInList = false
                }
              }
            }
          }
          return isInList
        })
        .sort((a, b) =>
          variantSortAsc ? (a.salesPrice < b.salesPrice ? -1 : 1) : a.salesPrice < b.salesPrice ? 1 : -1
        )
    } else {
      return []
    }
  }, [selectedFilterOptions, variantSortAsc, masterProduct])

  const possibleVariants = useMemo(() => {
    const possibleVariants: Record<string, string>[] = []
    if (masterProduct && masterProduct.variants) {
      masterProduct.variants.map((vpd) => {
        const possibleVariant: Record<string, string> = {}

        //console.log(vpd.sku)
        vpd.attributes.map((avd) => {
          possibleVariant[avd.attributeId] = avd.value
        })
        possibleVariants.push(possibleVariant)
      })
      //console.dir(possibleVariants)
      return possibleVariants
    } else {
      return []
    }
  }, [masterProduct])

  return { filteredVariants, possibleVariants }
}
