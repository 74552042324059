import { ensureStartingSlash, withoutTrailingSlash } from "@/lib/link-utils/url-utils"
import { PdpContext } from "@/lib/storefront/product/pdp-service"
import { getRenderedRichText } from "@/components/ui/helpers/richtextRenderer"
import StoryblokAssetClient from "@/components/storyblok/common/StoryblokAssetClient"
import { UbProductDetailViewStoryblok } from "@/components/storyblok/component-types"

type ProductDetailPrintCatalogLinkProps = {
  pdpContext: PdpContext
  blok: UbProductDetailViewStoryblok
}
// DE 629796 https://media.udobaer.de/frontend/getcatalog.do?catalogId=629796&startpage=
// AT var catalogId = '629781'; https://media.udobaer.at/frontend/getcatalog.do?catalogId=629781&startpage=
// CH var catalogId = '629786'; https://media.udobaer.ch/frontend/getcatalog.do?catalogId=629786&startpage=
// CH-FR 629791: https://media.udobaer.ch/frontend/getcatalog.do?catalogId=629791&startpage=
// FR var catalogID = '629801'; https://media.udobaer.fr/frontend/getcatalog.do?catalogId=629801&startpage=
// NL var catalogId = '629806';  https://media.udobaer.nl/frontend/getcatalog.do?catalogId=629806&startpage=

export default function ProductDetailPrintCatalogLink({ pdpContext, blok }: ProductDetailPrintCatalogLinkProps) {
  const catalogPage = pdpContext.productAndArticle.article?.printcatalogPrimaryPage ?? -1
  const catalogUrl =
    withoutTrailingSlash(pdpContext.mediaBaseUrl) +
    withoutTrailingSlash(ensureStartingSlash(pdpContext.catalogEndpoint))
  const catalogHref = `${catalogUrl}?catalogId=${blok.catalog_id}&startpage=${catalogPage}`
  const label = JSON.parse(JSON.stringify(blok.catalog_label).replaceAll("${page}", catalogPage.toString()))
  const enabled = blok.catalog_enabled === true

  return (
    enabled &&
    catalogPage > 0 && (
      <a className="flex flex-row items-center" href={catalogHref} target="_blank" rel="noopener noreferrer">
        <StoryblokAssetClient className="mr-1 max-h-10 max-w-10" asset={blok.catalog_image} />
        {blok.catalog_label && <div className="w-full">{getRenderedRichText(label)}</div>}
      </a>
    )
  )
}
